import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  loginBoxContainer: {
    maxWidth: 550,
    background: "white",
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      width: "90%",
    },
  },
  logo: {
    width: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
  actionButton: {
    marginTop: theme.spacing(2),
  },
  background: {
    backgroundColor: theme.palette.colors.hausPrimary,
  },
  copyRight: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
}));
