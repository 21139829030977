import "./App.css";
import React, { useEffect } from "react";
import Routes from "./routes";
import SideBar from "./shared/components/SideBar";
import { CssBaseline, makeStyles } from "@material-ui/core";
import Loading from "./shared/components/Loading";
import { useAuthenStore, useCookiePolicyStore } from "./store-mobx";
import { CookieUsageDialog } from "./cookie-policy/CookieUsageDialog";

const useStyles = makeStyles(() => ({
  wrapper: {
    top: "0",
    height: "100vh",
  },
}));

const App = () => {
  const classes = useStyles();
  const authStore = useAuthenStore();
  const cookiePolicyStore = useCookiePolicyStore();
  useEffect(() => {
    cookiePolicyStore.checkIsAccepted();
    (async () => await authStore.doCheckIsLoggedIn())();
  }, []);
  return (
    <div className={classes.wrapper}>
      <CssBaseline />
      <Loading isLoading={authStore.isLoading}>
        <SideBar />
        <Routes />
        <CookieUsageDialog />
      </Loading>
    </div>
  );
};

export default App;
