import Typography from "@material-ui/core/Typography";
import React from "react";
import { useHistory } from "react-router-dom";

export const NavigateToLoginPageButton = () => {
  const history = useHistory();
  return (
    <>
      <Typography component={"span"} style={{ display: "inline" }}>
        Already have an account?&nbsp;&nbsp;
      </Typography>
      <Typography
        component={"span"}
        style={{ display: "inline", cursor: "pointer", fontWeight: 900 }}
        color={"secondary"}
        onClick={() => history.push("/login")}
      >
        Click here to Login
      </Typography>
    </>
  );
};
