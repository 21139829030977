import React from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Loading from "../../../shared/components/Loading";
import useStyles from "./loginForm.style";
import { useAuthenStore } from "../../../store-mobx/index";
import { observer } from "mobx-react-lite";
import { Redirect, useHistory } from "react-router-dom";

const LoginFormValidationSchema = Yup.object().shape({
  username: Yup.string().nullable(true).trim().required("Username is required"),
  password: Yup.string().nullable(true).trim().required("Password is required"),
});

const loginFormInitialValues = { username: "", password: "" };

const LoginForm = observer(() => {
  const classes = useStyles();
  const {
    location: { state },
  } = useHistory();

  const authenStore = useAuthenStore();
  const { isLoading, errorMessage, isLoggedIn, currentUser } = authenStore;

  if (isLoggedIn && currentUser) {
    let navigationPath = "/";
    if (state && state.referrer && state.referrer.pathname) {
      navigationPath = state.referrer.pathname;
    }

    if (currentUser.forceChangePassword) {
      navigationPath = "/changePassword";
    } else if (currentUser.requireProfileInput) {
      navigationPath = "/manageProfile";
    }
    return <Redirect to={{ pathname: navigationPath }} />;
  }

  const onSubmit = async (formValues) => {
    const { username, password } = formValues;
    await authenStore.doLogin(username, password);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ ...loginFormInitialValues }}
      validationSchema={LoginFormValidationSchema}
    >
      {(formikProps) => {
        const { values, handleChange, errors } = formikProps;
        return (
          <Form>
            <Loading isLoading={isLoading}>
              <TextField
                fullWidth
                margin={"normal"}
                name="username"
                type="text"
                label="Email"
                onChange={handleChange}
                value={values.username}
                error={!!errors.username}
                helperText={errors.username}
              />
              <TextField
                fullWidth
                margin={"normal"}
                name="password"
                type="password"
                label="Password"
                onChange={handleChange}
                value={values.password}
                error={!!errors.password}
                helperText={errors.password}
              />
              {errorMessage && (
                <Typography color="error" variant="h5">
                  {errorMessage}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                fullWidth
                color="primary"
                className={classes.loginButton}
              >
                Login
              </Button>
            </Loading>
          </Form>
        );
      }}
    </Formik>
  );
});

export default LoginForm;
