import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthenStore } from "../../store-mobx";
import { observer } from "mobx-react-lite";

const PrivateRoute = observer(({ component: Component, ...routeProps }) => {
  const { currentUser } = useAuthenStore();
  return (
    <Route
      {...routeProps}
      render={(innerProps) =>
        !!currentUser ? (
          <Component {...innerProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { referrer: innerProps.location },
            }}
          />
        )
      }
    />
  );
});

export default PrivateRoute;
