import React from "react";
import { Button, Link, Slide, Snackbar } from "@material-ui/core";
import { useCookiePolicyStore } from "../store-mobx";
import { observer } from "mobx-react-lite";

const TransitionUp = (props) => {
  return <Slide {...props} direction="up" />;
};

const CookiePolicyLink = () => {
  return (
    <Link color={"secondary"} href={"/cookies-policy"} target={"_blank"}>
      Cookies Policy
    </Link>
  );
};

export const CookieUsageDialog = observer(() => {
  const cookiePolicyStore = useCookiePolicyStore();
  const { _isAccepted: isAccepted } = cookiePolicyStore;

  const handleAccept = () => {
    cookiePolicyStore.acceptCookiesPolicy();
  };

  return (
    <Snackbar
      open={!isAccepted}
      onClose={handleAccept}
      TransitionComponent={TransitionUp}
      key={"cookie-policy"}
      message={
        <p>
          This website use cookies to personalize and enhance your experience on
          our site. Please visit our <CookiePolicyLink /> to learn more on the
          detail of cookies collected by us and its purposes. By clicking
          "accept", you agree to our use of cookies.
        </p>
      }
      action={
        <Button color="secondary" size="small" onClick={handleAccept}>
          Accept
        </Button>
      }
    />
  );
});
