import React from "react";
import LoadingIcon from "./LoadingIcon";
import PropTypes from "prop-types";

export const Loading = (props) => {
  const { isLoading } = props;

  if (isLoading) {
    return <LoadingIcon />;
  }

  return props.children;
};

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default Loading;
