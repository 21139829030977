import React from "react";
import {
  makeStyles,
  TableCell,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  danger: {
    color: theme.palette.colors.danger,
  },
}));

const CustomTableCell = ({ isCancelled, width, ...rest }) => {
  const theme = useTheme();
  const padding = useMediaQuery(theme.breakpoints.up("md"))
    ? "default"
    : "none";
  const classes = useStyles();
  return (
    <TableCell
      className={isCancelled ? classes.danger : ""}
      padding={padding}
      {...rest}
    />
  );
};

CustomTableCell.propTypes = {
  isCancelled: PropTypes.bool.isRequired,
};

export default CustomTableCell;
