import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import MainLayout from "../Layout/MainLayout";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useNoBorderTableCell = makeStyles({
  root: {
    borderBottom: "none",
  },
});

const useMarginLeftCal = (indent) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  return matches ? indent * 2 : indent;
};

const NoBorderTableCell = (props) => {
  const classes = useNoBorderTableCell();
  return <TableCell {...props} classes={{ root: classes.root }} />;
};

const SectionHeader = (props) => {
  return (
    <Box my={3}>
      <Typography variant={"h4"}>{props.children}</Typography>
    </Box>
  );
};

const SubSectionHeader = (props) => {
  const ml = useMarginLeftCal(props.indent ? props.indent : 1);
  return (
    <Box my={2} ml={ml}>
      <Typography variant={"h6"}>{props.children}</Typography>
    </Box>
  );
};

const Paragraph = (props) => {
  const ml = useMarginLeftCal(props.indent);

  return (
    <Box my={1} ml={ml}>
      <Typography component={"div"}>{props.children}</Typography>
    </Box>
  );
};

const DefinitionTable = () => {
  const rows = useMemo(() => {
    return [
      {
        headers: ["Act"],
        detail: "the Personal Data Protection Act B.E. 2562 (A.D. 2019).",
      },
      {
        headers: ["Company"],
        detail: "Haus Education Co., Ltd.",
      },
      {
        headers: ["Data Controller"],
        detail:
          "a natural or juristic person having the powers and duties to make decisions regarding the collection, use, and disclosure of Personal Data.",
      },
      {
        headers: ["Data Processor"],
        detail:
          "a natural or juristic person who operates in relation to the collection, use, or disclosure of Personal Data pursuant to the orders given by or on behalf of a Data Controller, whereby such natural or juristic person is not the Data Controller.",
      },
      {
        headers: ["Data Subject"],
        detail: "a natural person who is the owner of Personal Data.",
      },
      {
        headers: ["Personal Data"],
        detail:
          "any information relating to a natural person which enables the identification of such natural person, whether directly or indirectly (including Sensitive Personal Data), but specifically not including the information of a deceased natural person.",
      },
      {
        headers: ["Process", "Processing", "Processed"],
        detail:
          "any operation or set of operations which is performed on Personal Data or sets of Personal Data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.",
      },
      {
        headers: ["Sensitive Personal Data"],
        detail:
          "Personal Data pertaining to racial, ethnic origin, political opinions, cult, religious or philosophical beliefs, sexual behavior, criminal records, health data, disability, trade union information, genetic data, biometric data, or of any data which may affect the data subject in the same manner as mentioned under Section 26 of the Act.",
      },
    ];
  }, []);

  return (
    <Table>
      <TableBody>
        {rows.map((row, i) => (
          <TableRow key={i}>
            <NoBorderTableCell>
              {row.headers.map((h, i) => {
                return (
                  <span key={i}>
                    {i > 0 ? (
                      <>
                        &nbsp;or&nbsp;
                        <br />
                      </>
                    ) : (
                      <></>
                    )}
                    <strong>"{h}"</strong>
                  </span>
                );
              })}
            </NoBorderTableCell>
            <NoBorderTableCell>means</NoBorderTableCell>
            <NoBorderTableCell>{row.detail}</NoBorderTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const TypeAndPurposeTable = () => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Data Subjects</TableCell>
          <TableCell>Types of Personal Data collected by the Company</TableCell>
          <TableCell>Purposes for the Collection and Use</TableCell>
          <TableCell>Legal basis for the collection and use</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <Paragraph>Students</Paragraph>
          </TableCell>
          <TableCell>
            <Paragraph>
              Name, family name, date of birth, identification number, passport
              number, residential address, contact information, photograph,
              video footage and educational information
            </Paragraph>
          </TableCell>
          <TableCell rowSpan={2}>
            <Paragraph>
              <ul>
                <li>
                  To perform our contract with you and to provide any products
                  and/or services e.g. class booking and study record
                  delivering;
                </li>
                <li>
                  To offers privileges and other information including
                  eligibility to attend special activities;{" "}
                </li>
                <li>
                  To improve products and/or services that are better and
                  suitable for your need;{" "}
                </li>
                <li>
                  To promote the Company’s product/service and other marketing
                  purposes including advertisement{" "}
                </li>
                <li>
                  To comply with relevant or applicable law e.g. Taxation Law.
                </li>
              </ul>
            </Paragraph>
          </TableCell>
          <TableCell rowSpan={2}>
            <Paragraph>
              <ul>
                <li>
                  Necessary for the purpose of the legitimate interests pursued
                  by the Company;
                </li>
                <li>Necessary to fulfil a contract; and/or</li>
                <li>Necessary to fulfil an obligation by law.</li>
              </ul>
            </Paragraph>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Parents</TableCell>
          <TableCell>Name, family name and contact information</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const DisclosureOfPersonalDataTable = () => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Data Subjects</TableCell>
          <TableCell>Types of Personal Data collected by the Company</TableCell>
          <TableCell>
            Purpose and legal basis for the disclosure of the Personal Data
          </TableCell>
          <TableCell>
            Type of a third party that is a recipient of the Personal Data
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Students</TableCell>
          <TableCell>
            Name, family name, date of birth, identification number, passport
            number, residential address, contact information, and educational
            information
          </TableCell>
          <TableCell rowSpan={2}>
            Necessary to fulfil an obligation by law and/or legitimate interests
            of the Company.
          </TableCell>
          <TableCell rowSpan={2}>Relevant authorities (if required).</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Parents</TableCell>
          <TableCell>Name, family name and contact information</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const PrivacyPolicyPage = observer(() => {
  return (
    <MainLayout>
      <Box mb={3}>
        <Typography component={"span"} variant={"h3"}>
          Privacy Policy
        </Typography>
      </Box>
      <Box>
        <Paragraph>
          <strong>Haus Education Co., Ltd. </strong>(the{" "}
          <strong>“Company”</strong>), as a Data Controller of your Personal
          Data (as defined below), has collected, used and retained your
          personal information (<strong>“Personal Data”</strong>) for the
          purposes as mentioned below. Since the information which the Company
          has collected from you is considered Personal Data, which is protected
          under the Personal Data Protection Act B.E. 2562 (A.D. 2019) (the
          “Act”). The Company recognize the importance of the protection of
          personal data and intends to processed the information in accordance
          with the relevant provisions set out in the Act, the Company prepared
          this notification in order to help you understand your rights under
          the Act, our purposes for the collection, use or disclosure of your
          information, as well as the necessity of the processed information.
        </Paragraph>
        <Paragraph>
          The Policy applies to all types and formats of Personal Data being
          Processed by the Company, whether in hard copy or electronic forms.
        </Paragraph>
        <Paragraph>
          Therefore, it is advisable that you read this Policy and ensure that
          you understand it thoroughly for your own benefits.
        </Paragraph>
      </Box>
      <Box>
        <SectionHeader>Definition</SectionHeader>
        <Paragraph>
          The following terms used herein shall have the meanings set forth
          below:
        </Paragraph>
        <DefinitionTable />
      </Box>
      <Box>
        <SectionHeader>How We Collect Your Personal Data </SectionHeader>
        <Paragraph>
          The Company collects your Personal Data directly from you in a variety
          of sources including, but not limited to:
        </Paragraph>
        <Paragraph>
          <ul>
            <li>
              Online Channel: the Company may collect your Personal Data when
              you register for an account, provide information on the Company’s
              website, participate in a survey, contest, subscribe to
              promotional offer, or receive marketing communication or otherwise
              connect with us via any social platform.
            </li>
            <li>
              Offline Interaction: the Company may collect your Personal Data
              from offline, such as when you visit our offices, register or
              attend classroom, contact us via phone, or attend our events or
              give us business cards.{" "}
            </li>
          </ul>
        </Paragraph>
        <Paragraph>
          In collecting your Personal Data, the Company shall collect the
          Personal Data as necessary only for the Company’s legitimate basis
          pursuant to the purposes, in which you were informed before or while
          collecting Personal Data.
        </Paragraph>
      </Box>
      <Box>
        <SectionHeader>
          Type and Purposes of Personal Data Collected
        </SectionHeader>
        <Paragraph>
          The Company has collected and used your personal data for the purposes
          as follows for the greatest benefits in fulfilling your needs:
        </Paragraph>
        <TypeAndPurposeTable />
      </Box>
      <Box>
        <SectionHeader>Retention Period</SectionHeader>
        <Paragraph>
          The Company will retain your Personal Data for as long as necessary
          during the period you are a customer or under relationship with the
          Company, or for as long as necessary in connection with the purposes
          set out in this Policy, unless law requires or permits longer
          retention period. For instance, retention for proving and examining in
          the event of dispute within legal prescription period of not over 10
          years.
        </Paragraph>
        <Paragraph>
          The Company may erase destroy, or anonymize the Personal Data when it
          is no longer necessary or when the period lapses.
        </Paragraph>
      </Box>
      <Box>
        <SectionHeader>Disclosure of Personal Data</SectionHeader>
        <Paragraph>
          For the greatest benefits in fulfilling your needs, kindly be informed
          that the Company may disclose the collected Personal Data to the
          following persons:
        </Paragraph>
        <DisclosureOfPersonalDataTable />
      </Box>
      <Box>
        <SectionHeader>International Transfer of Personal Data</SectionHeader>
        <Paragraph>
          Where the Company transfer the Personal Data to a destination outside
          Thailand, we shall ensure a similar degree of protection is afforded
          to it by ensuring at least one of the following safeguards is
          implemented:
          <ol type={"a"}>
            <li>
              only transfer your Personal Data to countries that have been
              deemed to provide an adequate level of protection for personal
              data;
            </li>
            <li>
              inform you the inadequate personal data protection standards of
              the destination country and obtain your consent; or
            </li>
            <li>
              enter into the specific contracts which give the Personal Data the
              same protection it has in Thailand.
            </li>
          </ol>
        </Paragraph>
      </Box>
      <Box>
        <SectionHeader>Security and Protection of Personal Data</SectionHeader>
        <Paragraph>
          The Company endeavour to ensure the security of your Personal Data
          through our internal security measures. We also require our staff and
          third-party contractors to follow our applicable privacy standards and
          to exercise due care and measures when using, sending or transferring
          your personal data.
        </Paragraph>
        <Paragraph>
          The Company shall ensure that the Personal Data will be protected
          against unauthorized Processing or any unlawful action. The Company
          may apply administrative safeguard measures, technical safeguard
          measures, and physical safeguard measures, to control access to the
          Personal Data, which may include:
          <ol type={"a"}>
            <li>
              establishing and implementing measures to control access to the
              Personal Data and the equipment which has been used for the
              Processing of the Personal Data;
            </li>
            <li>
              establishing and implementing procedures that control and validate
              a person’s access to the Personal Data;
            </li>
            <li>
              monitoring and managing access to the Personal Data to ensure that
              there will be no unauthorized access, Processing, or theft, of the
              Personal Data; and
            </li>
            <li>
              establishing and implementing measures to record and track
              activities relating to the Processing of the Personal Data
            </li>
          </ol>
        </Paragraph>
        <Paragraph>
          Processing of the Personal Data The Company will keep the Personal
          Data in a secure place that is properly managed and suitable for the
          form (paper or electronic) of the Personal Data being retained.
        </Paragraph>
        <Paragraph>
          If you suspect that there is any unauthorized processing of Personal
          Data, or have any enquiry, the Data Subject you may contact the
          Company at the available channels as detailed under “Contact Channels”
          Section below.
        </Paragraph>
      </Box>
      <Box>
        <SectionHeader>Rights of the Data Subject</SectionHeader>
        <Paragraph>
          As the owner of the personal data or as referring to as the “Data
          Subject” under the Act, you are entitled to the following rights with
          respect to your personal data:
        </Paragraph>

        <SubSectionHeader>
          1) Right to withdraw the given consent
        </SubSectionHeader>
        <Paragraph indent={1}>
          For any Personal Data which the Company has collected, used or
          disclosed based on consent obtained from a Data Subject, the Data
          Subject may withdraw his/her consent at any time.
        </Paragraph>

        <SubSectionHeader>2) Right to access </SubSectionHeader>
        <Paragraph indent={1}>
          Data Subjects have the right to request access to their Personal Data
          collected by the Company, as well as the right to obtain a copy of
          such the Personal Data at any time.
        </Paragraph>

        <SubSectionHeader>
          3) Right to request disclosure of the source from which the Data
          Controller obtained the Personal Data of the Data Subject
        </SubSectionHeader>
        <Paragraph indent={1}>
          In the case that the Company obtained the Personal Data of a Data
          Subject from a source other than directly from the Data Subject, the
          Data Subject has the right to request the Company to disclose the
          source which provided the Personal Data to the Company.
        </Paragraph>

        <SubSectionHeader>4) Right to data portability</SubSectionHeader>
        <Paragraph indent={1}>
          Data Subjects have the right to request a copy of his/her Personal
          Data if the Company organizes such Personal Data in automatic
          machine-readable or usable format and can be processed or disclosed by
          automatic means; to request the Company to send or transfer the
          Personal Data in such format directly to other Data Controllers if
          doable by automatic means; and to request to obtain the Personal Data
          in such format sent or transferred by the Company directly to other
          Data Controller, unless it is impossible to do so because of the
          technical circumstances
        </Paragraph>

        <SubSectionHeader>
          5) Right to object to the Processing of Personal Data which the
          Company is authorized to Process without requiring consent from the
          Data Subject
        </SubSectionHeader>
        <Paragraph indent={1}>
          A Data Subject has the right to object to:
          <ol type={"a"}>
            <li>
              the Processing of Personal Data which relies on the public
              interest or the legitimate interests of the Data Controller as the
              rationale for the Processing (unless the Company can demonstrate
              compelling legitimate grounds for the Processing that override the
              interests of the Data Subject, or the Company can demonstrate that
              the Processing is for the establishment, exercise or defense of
              legal claims);
            </li>
            <li>
              the Processing of Personal Data for the purpose of direct
              marketing; and
            </li>
          </ol>
        </Paragraph>
        <SubSectionHeader>
          6) Right to request that the given Personal Data be destroyed or be
          made unidentifiable by way of anonymization
        </SubSectionHeader>
        <Paragraph indent={1}>
          A Data Subject has the right to request the Company to erase its
          Personal Data or to make it unidentifiable by way of anonymization,
          but only where:
          <ol type={"a"}>
            <li>
              the Personal Data is no longer necessary in relation to the
              purpose for which it was collected;{" "}
            </li>
            <li>
              consent is withdrawn and the Company has no legal ground for the
              Processing of the Personal Data;{" "}
            </li>
            <li>
              the Data Subject objects to the Processing and the Company cannot
              demonstrate compelling legitimate grounds for the Processing that
              override the interests of the Data Subject, or the Company cannot
              demonstrate that the Processing is for the establishment, exercise
              or defense of legal claims; or
            </li>
            <li>the Personal Data has been unlawfully Processed.</li>
          </ol>
        </Paragraph>

        <SubSectionHeader>
          7) Right to restrict the Processing of Personal Data
        </SubSectionHeader>
        <Paragraph indent={1}>
          <ol type={"a"}>
            <li>
              the Personal Data is being verified, updated or rectified at the
              request of the Data Subject;
            </li>
            <li>
              the Personal Data could be erased due to unlawful Processing, but
              the Data Subject does not want it erased;
            </li>
            <li>
              the Personal Data is no longer needed for the purpose of
              Processing, but it is required by the Data Subject for the
              establishment, exercise or defense of legal claims; or
            </li>
            <li>
              the Data Subject has exercised its right in Article 3.1.5, and the
              Company is in the process of proving that the Company’s legitimate
              interest in Processing the Personal Data overrides the rights of
              the Data Subject.{" "}
            </li>
          </ol>
        </Paragraph>

        <SubSectionHeader>
          8) Right to Rectification of Personal Data to be Correct, Complete,
          and Up-to-Date
        </SubSectionHeader>
        <Paragraph indent={1}>
          If any change occurs which may affect the Data Subject’s provided
          Personal Data, or if the Data Subject later finds that the provided
          Personal Data is false or incomplete, the Data Subject may request the
          Company to update or amend the provided Personal Data at any time.
        </Paragraph>

        <SubSectionHeader>9) Right to Complain</SubSectionHeader>
        <Paragraph indent={1}>
          If a Data Subject finds or suspects that there is any unauthorized
          Processing of Personal Data, or any breach of the Act by the Company,
          its employees or its Data Processor, the Data Subject may make a
          complaint to the Company or to the relevant government.
        </Paragraph>

        <Paragraph>
          If you wish to exercise any of your rights, you may inform us or
          request to exercise your right by using any of the available channels.
        </Paragraph>
      </Box>
      <Box>
        <SectionHeader>Review and Changes of Policy</SectionHeader>
        <Paragraph>
          The Company may review this Policy to ensure that it remains in
          adherence to laws, any significant business changes, and any
          suggestions and opinions from other organizations. The Company shall
          announce and review amended policies thoroughly before implementing
          all the changes.
        </Paragraph>
      </Box>
      <Box>
        <SectionHeader>Contact Information</SectionHeader>
        <Paragraph>
          If you wish to exercise any of your rights mentioned in this letter,
          you may inform us or request to exercise your right by using any of
          the following channels:
        </Paragraph>
        <Table>
          <TableBody>
            <TableRow>
              <NoBorderTableCell>
                <Paragraph>
                  <strong>Tel:</strong>
                </Paragraph>
              </NoBorderTableCell>
              <NoBorderTableCell>
                <Paragraph>02-010-5684</Paragraph>
                <Paragraph>096-659-6559</Paragraph>
                <Paragraph>098-659-4456</Paragraph>
              </NoBorderTableCell>
            </TableRow>
            <TableRow>
              <NoBorderTableCell>
                <Paragraph>
                  <strong>Email:</strong>
                </Paragraph>
              </NoBorderTableCell>
              <NoBorderTableCell>
                <Paragraph>info@hausacademy.com</Paragraph>
              </NoBorderTableCell>
            </TableRow>
            <TableRow>
              <NoBorderTableCell colSpan={2}>
                <Paragraph>
                  Please specify the subject as : “Request to exercise a data
                  subject’s rights”
                </Paragraph>
              </NoBorderTableCell>
            </TableRow>
            <TableRow>
              <NoBorderTableCell>
                <Paragraph>
                  <strong>Address:</strong>
                </Paragraph>
              </NoBorderTableCell>
              <NoBorderTableCell>
                <Paragraph>Haus Education Co., Ltd. </Paragraph>
                <Paragraph>
                  29/1 Piyaplace Bldg. Tower B, 14th floor, Room 14A{" "}
                </Paragraph>
                <Paragraph>Soi Langsuan, Ploenchit Road, Lumpini</Paragraph>
                <Paragraph>Patumwan Bangkok 10330</Paragraph>
              </NoBorderTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </MainLayout>
  );
});
