import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const getStatusText = (status) => {
  switch (status) {
    case "CC_SC":
      return "Urgent Cancel";
    case "CC_S":
      return "Cancelled";
    case "CC_T":
      return "Teacher Cancel";
    case "CC_H":
      return "Haus Cancel";
    default:
      return status;
  }
};

const getStatusColor = (status) => {
  if (status.indexOf("CC_") === 0) {
    return "danger";
  }

  if (status === "Booked") {
    return "info";
  }

  return "success";
};

const useStyles = makeStyles(({ status }) => {
  return { ...status };
});

const CourseStatus = ({ status }) => {
  const classes = useStyles();
  return (
    <Chip
      className={classes[getStatusColor(status)]}
      label={getStatusText(status)}
    />
  );
};

CourseStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default CourseStatus;
