import React from "react";
import { useHistory } from "react-router-dom";
import {
  Divider,
  Drawer,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
} from "@material-ui/core";

import {
  Home as HomeIcon,
  CalendarToday as CalendarTodayIcon,
  Face as FaceIcon,
  PersonAdd as PersonAddIcon,
  Policy as PolicyIcon,
} from "@material-ui/icons";
import { useAuthenStore, useSidebarStore } from "../../store-mobx";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  head: {
    backgroundColor: theme.palette.colors.hausPrimary,
  },
  img: {
    objectFit: "scale-down",
  },
  root: {
    width: 40,
    height: 40,
  },
  sideBarTitle: {
    fontWeight: 900,
  },
  secondary: {
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
  listIcon: {
    color: theme.palette.colors.hausPrimary,
  },
  navItem: {
    fontWeight: 400,
  },
}));

const companyName = "Haus Academy";
const siteName = "Student Portal";

const SideBar = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const sidebarStore = useSidebarStore();
  const { isLoggedIn } = useAuthenStore();
  const { isOpen } = sidebarStore;

  const navigate = (destination) => {
    history.push(destination);
    sidebarStore.toggle();
  };

  return (
    <Drawer open={isOpen}>
      <div className={classes.list}>
        <div>
          <ListItem button className={classes.head}>
            <ListItemText
              classes={{
                secondary: classes.secondary,
                primary: classes.sideBarTitle,
              }}
              primary={companyName}
              secondary={siteName}
              onClick={() => navigate("/")}
            />
          </ListItem>
          <Divider />
          {isLoggedIn ? (
            <React.Fragment>
              <ListItem button divider={true} onClick={() => navigate("/")}>
                <ListItemIcon className={classes.listIcon}>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.navItem }}
                  primary={"Home"}
                />
              </ListItem>
              <ListItem
                button
                divider={true}
                onClick={() => navigate("/classes")}
              >
                <ListItemIcon className={classes.listIcon}>
                  <CalendarTodayIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.navItem }}
                  primary={"Classes"}
                />
              </ListItem>
              <ListItem
                button
                divider={true}
                onClick={() => navigate("/manageProfile")}
              >
                <ListItemIcon className={classes.listIcon}>
                  <FaceIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.navItem }}
                  primary={"Manage Profile"}
                />
              </ListItem>
              <ListItem
                button
                divider={true}
                onClick={() => navigate("/changePassword")}
              >
                <ListItemIcon className={classes.listIcon}>
                  <FaceIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.navItem }}
                  primary={"Change Password"}
                />
              </ListItem>
              <ListItem
                button
                divider={true}
                onClick={() => navigate("/privacy-policy")}
              >
                <ListItemIcon className={classes.listIcon}>
                  <PolicyIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.navItem }}
                  primary={"Privacy Policy"}
                />
              </ListItem>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ListItem
                button
                divider={true}
                onClick={() => navigate("/register")}
              >
                <ListItemIcon className={classes.listIcon}>
                  <PersonAddIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.navItem }}
                  primary={"Register"}
                />
              </ListItem>
              <ListItem
                button
                divider={true}
                onClick={() => navigate("/privacy-policy")}
              >
                <ListItemIcon className={classes.listIcon}>
                  <PolicyIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.navItem }}
                  primary={"Privacy Policy"}
                />
              </ListItem>
            </React.Fragment>
          )}
        </div>
      </div>
    </Drawer>
  );
});

export default SideBar;
