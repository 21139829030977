import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ChangePasswordPage, LoginPage, RegisterPage } from "./auth";
import MainPage from "./main/components/MainPage";
import PrivateRoute from "./shared/components/PrivateRoute";
import ManageProfilePage from "./auth/components/ManageProfilePage";
import TimetablePage from "./timetable/components/TimetablePage";
import { CookiePolicyPage } from "./cookie-policy/CookiePolicyPage";
import { PrivacyPolicyPage } from "./privacy-policy/PrivacyPolicyPage";

const Routes = () => (
  <Switch>
    <Route exact path="/login" component={LoginPage} />
    <Route exact path="/register" component={RegisterPage} />
    <Route exact path="/cookies-policy" component={CookiePolicyPage} />
    <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
    <PrivateRoute
      exact
      path={"/changePassword"}
      component={ChangePasswordPage}
    />
    <PrivateRoute exact path={"/manageProfile"} component={ManageProfilePage} />
    <PrivateRoute exact path={"/classes"} component={TimetablePage} />
    <Route path="/" component={MainPage} />
  </Switch>
);

export default withRouter(Routes);
