import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  registerContainer: theme.mixins.container,
  loginNavigationContainer: {
    marginTop: theme.spacing(2),
  },
  steps: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));
