import { makeAutoObservable } from "mobx";
import { CourseApi } from "../api";

export class CourseStore {
  courses = [];
  events = [];
  isLoading = false;
  isLoadingEvent = false;
  errorMessage = null;

  constructor(authenStore) {
    makeAutoObservable(this);
    this.authenStore = authenStore;
  }

  async fetchCourses(filter) {
    this.setIsLoading = true;
    this.setErrorMessage = null;
    try {
      const { currentUser } = this.authenStore;
      const { data } = await CourseApi.listClassesByStudentId(
        currentUser.id,
        filter
      );
      this.setCourses = data;
    } catch (e) {
      this.setErrorMessage = e.message;
    } finally {
      this.setIsLoading = false;
    }
  }

  async fetchCoursesForTimetable(filter) {
    this.setIsLoadingEvent = true;
    this.setErrorMessage = null;
    try {
      const { currentUser } = this.authenStore;
      const { data } = await CourseApi.listClassesForCalendarByStudentId(
        currentUser.id,
        filter
      );
      this.setEvents = data;
    } catch (e) {
      this.setErrorMessage = e.message;
    } finally {
      this.setIsLoadingEvent = false;
    }
  }

  reset() {
    this.setCourses = [];
    this.setEvents = [];
    this.setIsLoadingEvent = false;
    this.setIsLoading = false;
    this.setErrorMessage = null;
  }

  set setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }
  set setIsLoadingEvent(isLoadingEvent) {
    this.isLoadingEvent = isLoadingEvent;
  }

  set setCourses(courses) {
    this.courses = courses;
  }

  set setErrorMessage(errorMessage) {
    this.errorMessage = errorMessage;
  }

  set setEvents(events) {
    this.events = events;
  }
}
