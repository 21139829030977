import React from "react";
import { Link, makeStyles } from "@material-ui/core";
import Color from "color";

const useLinkStyles = makeStyles((theme) => {
  return {
    privacyLink: {
      color: Color(theme.palette.secondary.main).darken(0.15).hex(),
    },
  };
});

export const PrivacyPolicyLink = () => {
  const classes = useLinkStyles();
  return (
    <Link
      classes={{ root: classes.privacyLink }}
      href={"/privacy-policy"}
      target={"_blank"}
    >
      Privacy Policy
    </Link>
  );
};
