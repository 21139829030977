import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    margin: "auto",
  },
  bold: {
    fontWeight: 900,
  },
  sectionTitle: {
    fontWeight: 900,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  subHeaderRow: {
    height: theme.spacing(2),
  },
  subHeaderCell: {
    backgroundColor: theme.palette.colors.alternateBackgroundColor,
    whiteSpace: "nowrap",
  },
  paper: {
    overflowX: "scroll",
  },
}));
