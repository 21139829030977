import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { BrowserRouter as Router } from "react-router-dom";
import { StoreContextProvider } from "./store-mobx";
import Theme from "./theme";

const AppWithTheme = () => (
  <MuiThemeProvider theme={Theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <StoreContextProvider>
        <Router>
          <App />
        </Router>
      </StoreContextProvider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>
);

ReactDOM.render(<AppWithTheme />, document.getElementById("root"));
// registerServiceWorker();
