import { makeAutoObservable } from "mobx";
import { AuthApi } from "../api";

export class ProfileStore {
  isLoading = false;
  isChangePasswordSuccess = false;
  isUpdating = false;
  isUpdateProfileSuccess = false;
  personalInfo = null;
  errorMessage = null;

  constructor(authenStore) {
    makeAutoObservable(this);
    this.authenStore = authenStore;
  }

  async getPersonalInfo() {
    this.setIsLoading = true;
    try {
      await this.authenStore.doCheckIsLoggedIn();
      this.setPersonalInfo = this.authenStore.currentUser;
    } catch (e) {
      console.error(e);
    } finally {
      this.setIsLoading = false;
    }
  }

  async doChangePassword(oldPassword, newPassword) {
    this.setIsLoading = true;
    this.setIsChangePasswordSuccess = false;
    try {
      const { currentUser } = this.authenStore;
      await AuthApi.changePassword({
        id: currentUser.id,
        oldPassword,
        newPassword,
      });
      await this.authenStore.doCheckIsLoggedIn();
      this.setIsChangePasswordSuccess = true;
    } catch (e) {
      this.setErrorMessage = "Please input valid current password";
    } finally {
      this.setIsLoading = false;
    }
  }

  async updatePersonalInfo(personalInfo) {
    this.setIsUpdating = true;
    this.setIsLoading = true;

    try {
      this.setPersonalInfo = personalInfo;
      await AuthApi.updateStudentAccount(personalInfo);
      this.setIsUpdateProfileSuccess = true;
    } catch (e) {
      this.setErrorMessage = e.message;
      this.setIsUpdateProfileSuccess = false;
    } finally {
      this.setIsUpdating = false;
    }

    try {
      await this.authenStore.doCheckIsLoggedIn();
      this.setPersonalInfo = this.authenStore.currentUser;
    } catch (e) {
      console.error(e);
    } finally {
      this.setIsLoading = false;
    }
  }

  reset() {
    this.setIsLoading = false;
    this.setIsChangePasswordSuccess = false;
    this.setErrorMessage = null;
    this.setIsUpdating = false;
    this.setIsUpdateProfileSuccess = false;
    this.setPersonalInfo = false;
  }

  set setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  set setErrorMessage(errorMessage) {
    this.errorMessage = errorMessage;
  }

  set setIsChangePasswordSuccess(isChangePasswordSuccess) {
    this.isChangePasswordSuccess = isChangePasswordSuccess;
  }

  set setIsUpdating(isUpdating) {
    this.isUpdating = isUpdating;
  }

  set setIsUpdateProfileSuccess(isUpdateProfileSuccess) {
    this.isUpdateProfileSuccess = isUpdateProfileSuccess;
  }

  set setPersonalInfo(personalInfo) {
    this.personalInfo = personalInfo;
  }
}
