import { makeAutoObservable } from "mobx";

const hausCookieAcceptedKey = "haus-cookie-accepted";
const acceptedValue = "accepted";

export class CookiePolicyStore {
  _isAccepted = false;

  constructor() {
    makeAutoObservable(this);
  }

  checkIsAccepted() {
    const value = localStorage.getItem(hausCookieAcceptedKey);
    this.isAccepted = value === acceptedValue;
  }

  acceptCookiesPolicy() {
    localStorage.setItem(hausCookieAcceptedKey, acceptedValue);
    this.checkIsAccepted();
  }

  set isAccepted(value) {
    this._isAccepted = value;
  }
}
