import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useCourseStore } from "../../../store-mobx";
import Loading from "../../../shared/components/Loading";
import { observer } from "mobx-react-lite";

const useStyle = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    overflowX: "scroll",
    minWidth: "450px",
  },
}));

const convertToEvent = (c) => {
  const isCancelled = c.status.indexOf("CC_") === 0;
  return {
    id: c.id,
    title: `${c.level.subjectName} - ${c.teacher.nicknameEN}`,
    start: c.startTime,
    end: c.endTime,
    allDay: false,
    textColor: isCancelled ? "rgba(0, 0, 0, 0.7)" : "",
    backgroundColor: isCancelled ? "rgba(244,67,54, 0.2)" : "",
    borderColor: isCancelled ? "rgba(244,67,54, 0.2)" : "",
    extendedProps: {
      isCancelled,
    },
  };
};

const CourseCalendar = observer((props) => {
  const classes = useStyle();
  const { filter } = props;
  const courseStore = useCourseStore();
  const { isLoadingEvent, events: courses } = courseStore;

  useEffect(() => {
    (async () => {
      await courseStore.fetchCoursesForTimetable(filter);
    })();
  }, [filter]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let displayOptions = isMobile
    ? {
        aspectRatio: 1,
        height: 800,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "timeGridWeek,timeGridDay",
        },
        initialView: "timeGridWeek",
        eventMinWidth: "100",
        eventContent: renderMobileEventContent,
      }
    : {
        eventContent: renderEventContent,
      };

  const events = courses.map(convertToEvent);

  return (
    <Loading isLoading={isLoadingEvent}>
      <Paper className={classes.paper}>
        <FullCalendar
          aspectRatio={isMobile ? 1 : 2}
          plugins={[dayGridPlugin, timeGridPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialView="dayGridMonth"
          weekends={true}
          slotMinTime={"07:00:00"}
          slotMaxTime={"22:30:00"}
          events={events}
          dayMaxEvents={5}
          moreLinkClick={"popover"}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }}
          displayEventEnd={true}
          {...displayOptions}
        />
      </Paper>
    </Loading>
  );
});

function renderEventContent(eventContent) {
  const { isCancelled } = eventContent.event.extendedProps;
  return (
    <div
      style={{
        textDecoration: isCancelled ? "line-through" : "inherit",
      }}
    >
      <Typography variant={"caption"}>
        {eventContent.timeText}&nbsp;&nbsp;
      </Typography>
      <Typography variant={"caption"}>
        <strong>{eventContent.event.title}</strong>
      </Typography>
    </div>
  );
}

function renderMobileEventContent(eventContent) {
  const { isCancelled } = eventContent.event.extendedProps;
  return (
    <p
      style={{
        textDecoration: isCancelled ? "line-through" : "inherit",
      }}
    >
      {eventContent.event.title}
    </p>
  );
}

CourseCalendar.propTypes = {
  filter: PropTypes.object.isRequired,
};

export default CourseCalendar;
