import React, { useMemo } from "react";
import { Form, Formik } from "formik";
import FormLabel from "@material-ui/core/FormLabel";
import { Box, Checkbox, Link, makeStyles, TextField } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as Yup from "yup";
import { RegisterStep } from "./RegisterPage";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import Loading from "../../../shared/components/Loading";
import { transformPersonalInfoToFormValues } from "../../personalInfoFormHelper";
import { IdType } from "../../constants";
import { PrivacyPolicyLink } from "../../../shared/components/PrivacyPolicyLink";

const LeadType = ["Friend", "Social Network", "Website"];

const useStyle = makeStyles((theme) => {
  return {
    select: {
      width: "100%",
      marginBlockStart: `${theme.spacing(1)}px`,
      marginBlockEnd: `${theme.spacing(1)}px`,
    },
    label: {
      display: "block",
      fontWeight: "bold",
      marginTop: theme.spacing(3),
      fontSize: "1.2rem",
    },
    fullWidth: {
      width: "100%",
    },
  };
});

const validatePhoneNo = (value) => value && /^0\d{9}$/i.test(value);

const PersonalInfoFormValidationSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  firstnameEN: Yup.string().nullable().required("First name is required"),
  lastnameEN: Yup.string().nullable().required("Last name is required"),
  nicknameEN: Yup.string().nullable().required("Nickname is required"),
  firstnameTH: Yup.string().nullable().required("First name is required"),
  lastnameTH: Yup.string().nullable().required("Last name is required"),
  nicknameTH: Yup.string().nullable().required("Nickname is required"),
  idType: Yup.string()
    .nullable(true)
    .when("id", {
      is: (id) => !id,
      then: Yup.string().required("Id Type is required"),
    }),
  thaiId: Yup.string()
    .nullable(true)
    .when(["id", "idType"], {
      is: (id, idType) => idType === IdType.thaiId && !id,
      then: Yup.string().min(13).max(13).required("Thai Id is required."),
    }),
  passportNo: Yup.string()
    .nullable(true)
    .when(["id", "idType"], {
      is: (id, idType) => idType === IdType.passportNo && !id,
      then: Yup.string().required("Passport No is required."),
    }),
  passportIssuingCountry: Yup.string()
    .nullable(true)
    .when(["id", "idType"], {
      is: (id, idType) => idType === IdType.passportNo && !id,
      then: Yup.string().required("Passport Issuing Country is required."),
    }),
  streetAddress: Yup.string().nullable().required("Street Address is required"),
  city: Yup.string().nullable().required("City is required"),
  state: Yup.string().nullable().required("State is required"),
  postalCode: Yup.string().nullable().required("Postal Code is required"),
  mobile: Yup.string()
    .nullable()
    .test("validPhone", "Mobile No is not valid", validatePhoneNo)
    .required("Mobile No is required"),
  parentPhone: Yup.string()
    .nullable()
    .test("validPhone", "Parent Phone is not valid", validatePhoneNo)
    .required("Parent Phone No is required"),
  parentRelation: Yup.string()
    .nullable()
    .required("Parent Relation is required"),
  birthdate: Yup.mixed()
    .test("is-valid-date", "Date is not valid", (value) => {
      return moment(value, "DD/MM/YYYY")._isValid;
    })
    .required("Date of Birth is required"),
  school: Yup.string().nullable().required("School is required"),
  lead: Yup.string()
    .nullable()
    .when("id", {
      is: (id) => !id,
      then: Yup.string().required("Please select one option."),
    }),
  gradeId: Yup.number()
    .nullable()
    .min(1, "Please select one option.")
    .required("Please select one option."),
  acceptPrivacy: Yup.bool()
    .nullable()
    .when("id", {
      is: (id) => !id,
      then: Yup.bool().oneOf(
        [true],
        "Please review and accept the terms and conditions set out in Privacy Policy"
      ),
    }),
});

const personalInfoFormDefaultValue = {
  firstnameEN: "",
  lastnameEN: "",
  nicknameEN: "",
  firstnameTH: "",
  lastnameTH: "",
  nicknameTH: "",
  idType: IdType.thaiId,
  thaiId: "",
  passportNo: "",
  passportIssuingCountry: "",
  streetAddress: "",
  city: "",
  state: "",
  postalCode: "",
  mobile: "",
  parentPhone: "",
  parentRelation: "",
  birthdate: new Date(),
  school: "",
  lead: "",
  gradeId: 0,
  acceptPrivacy: false,
};

const PersonalInfoForm = (props) => {
  const classes = useStyle();
  const {
    onNext,
    isNewRegistration,
    onSubmit,
    personalInfo,
    grades,
    loading,
  } = props;

  const initialFormValue = useMemo(
    () => ({
      ...personalInfoFormDefaultValue,
      ...transformPersonalInfoToFormValues(personalInfo),
    }),
    [personalInfo]
  );

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={initialFormValue}
      validateOnChange={false}
      validationSchema={PersonalInfoFormValidationSchema}
    >
      {(formikProps) => {
        const { values, errors, handleChange, setFieldValue } = formikProps;
        return (
          <Loading isLoading={loading}>
            <Form>
              <FormLabel required={true} className={classes.label}>
                Name (Eng)
              </FormLabel>
              <TextField
                fullWidth
                name="firstnameEN"
                label="First Name(EN)"
                onChange={handleChange}
                value={values.firstnameEN}
                error={!!errors.firstnameEN}
                helperText={errors.firstnameEN}
                inputProps={{
                  readOnly: isNewRegistration,
                }}
              />
              <TextField
                fullWidth
                name="lastnameEN"
                label="Last Name(EN)"
                onChange={handleChange}
                value={values.lastnameEN}
                error={!!errors.lastnameEN}
                helperText={errors.lastnameEN}
                inputProps={{
                  readOnly: isNewRegistration,
                }}
              />
              <TextField
                fullWidth
                name="nicknameEN"
                label="Nickname(EN)"
                onChange={handleChange}
                value={values.nicknameEN}
                error={!!errors.nicknameEN}
                helperText={errors.nicknameEN}
              />
              <FormLabel required={true} className={classes.label}>
                Name (Thai)
              </FormLabel>
              <TextField
                fullWidth
                name="firstnameTH"
                label="First Name(TH)"
                onChange={handleChange}
                value={values.firstnameTH}
                error={!!errors.firstnameTH}
                helperText={errors.firstnameTH}
              />
              <TextField
                fullWidth
                name="lastnameTH"
                label="Last Name(TH)"
                onChange={handleChange}
                value={values.lastnameTH}
                error={!!errors.lastnameTH}
                helperText={errors.lastnameTH}
              />
              <TextField
                fullWidth
                name="nicknameTH"
                label="Nickname(TH)"
                onChange={handleChange}
                value={values.nicknameTH}
                error={!!errors.nicknameTH}
                helperText={errors.nicknameTH}
              />
              {isNewRegistration && (
                <>
                  <FormLabel required={true} className={classes.label}>
                    Thai ID Card No. or Passport No.
                  </FormLabel>
                  <RadioGroup
                    name="idType"
                    value={values.idType}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      disabled={isNewRegistration}
                      value={IdType.thaiId}
                      control={<Radio color="primary" />}
                      label="Thai ID Card No."
                    />
                    <FormControlLabel
                      disabled={isNewRegistration}
                      value={IdType.passportNo}
                      control={<Radio color="primary" />}
                      label="Passport No."
                    />
                  </RadioGroup>

                  {values.idType === IdType.thaiId && (
                    <TextField
                      fullWidth
                      autoComplete={"off"}
                      margin={"normal"}
                      label={"Thai Id"}
                      onChange={handleChange}
                      name={"thaiId"}
                      value={values.thaiId}
                      error={!!errors.thaiId}
                      helperText={errors.thaiId}
                      inputProps={{
                        readOnly: isNewRegistration,
                      }}
                    />
                  )}
                  {values.idType === IdType.passportNo && (
                    <TextField
                      fullWidth
                      autoComplete={"off"}
                      margin={"normal"}
                      label={"Passport No"}
                      onChange={handleChange}
                      name={"passportNo"}
                      value={values.passportNo}
                      error={!!errors.passportNo}
                      helperText={errors.passportNo}
                      inputProps={{
                        readOnly: isNewRegistration,
                      }}
                    />
                  )}
                  {values.idType === IdType.passportNo && (
                    <TextField
                      fullWidth
                      autoComplete={"off"}
                      margin={"normal"}
                      label={"Passport Issuing Country"}
                      onChange={handleChange}
                      name={"passportIssuingCountry"}
                      value={values.passportIssuingCountry}
                      error={!!errors.passportIssuingCountry}
                      helperText={errors.passportIssuingCountry}
                    />
                  )}
                </>
              )}
              <DatePicker
                autoOk
                label="Date of Birth"
                clearable
                disableFuture
                className={classes.fullWidth}
                format="DD/MM/YYYY"
                value={values.birthdate}
                onChange={(newDate) => setFieldValue("birthdate", newDate)}
              />
              <FormLabel required={true} className={classes.label}>
                Address
              </FormLabel>
              <TextField
                fullWidth
                name="streetAddress"
                label="Street"
                placeholder={"Building, Street, City, Province and Zip Code"}
                onChange={handleChange}
                value={values.streetAddress}
                error={!!errors.streetAddress}
                helperText={errors.streetAddress}
              />
              <TextField
                fullWidth
                name="city"
                label="City"
                placeholder={""}
                onChange={handleChange}
                value={values.city}
                error={!!errors.city}
                helperText={errors.city}
              />
              <TextField
                fullWidth
                name="state"
                label="State/Province"
                placeholder={""}
                onChange={handleChange}
                value={values.state}
                error={!!errors.state}
                helperText={errors.state}
              />
              <TextField
                fullWidth
                name="postalCode"
                label="Postal Code"
                placeholder={""}
                onChange={handleChange}
                value={values.postalCode}
                error={!!errors.postalCode}
                helperText={errors.postalCode}
              />
              <FormLabel required={true} className={classes.label}>
                Phone
              </FormLabel>
              <TextField
                fullWidth
                name="mobile"
                label="Mobile No."
                onChange={handleChange}
                value={values.mobile}
                error={!!errors.mobile}
                helperText={errors.mobile}
              />
              <TextField
                fullWidth
                name="parentPhone"
                label="Parent's Phone No."
                onChange={handleChange}
                value={values.parentPhone}
                error={!!errors.parentPhone}
                helperText={errors.parentPhone}
              />
              <TextField
                fullWidth
                name="parentRelation"
                label="Relation"
                placeholder={"Mom, Dad, Aunt"}
                onChange={handleChange}
                value={values.parentRelation}
                error={!!errors.parentRelation}
                helperText={errors.parentRelation}
              />
              <FormLabel required={true} className={classes.label}>
                Education
              </FormLabel>
              <TextField
                fullWidth
                name="school"
                label={"School"}
                onChange={handleChange}
                value={values.school}
                error={!!errors.school}
                helperText={errors.school}
              />
              <FormControl
                className={classes.fullWidth}
                error={!!errors.gradeId}
                margin={"dense"}
              >
                <InputLabel id="grade-label">Grade</InputLabel>
                <Select
                  labelId="grade-label"
                  id="grade"
                  name={"gradeId"}
                  value={values.gradeId}
                  onChange={handleChange}
                  className={classes.select}
                >
                  <MenuItem value={0}>Select</MenuItem>
                  {grades.map((g) => (
                    <MenuItem
                      value={g.id}
                      key={g.id}
                    >{`${g.name} - ${g.standard}`}</MenuItem>
                  ))}
                </Select>
                {!!errors.gradeId && (
                  <FormHelperText>{errors.gradeId}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                className={classes.fullWidth}
                error={!!errors.lead}
                margin={"dense"}
              >
                {isNewRegistration && (
                  <>
                    <InputLabel id="how-you-know">
                      How did you hear about us?
                    </InputLabel>
                    <Select
                      id="how-you-know"
                      name={"lead"}
                      value={values.lead}
                      onChange={handleChange}
                      className={classes.select}
                    >
                      <MenuItem value={""}>Select</MenuItem>
                      <MenuItem value={LeadType[0]}>{LeadType[0]}</MenuItem>
                      <MenuItem value={LeadType[1]}>{LeadType[1]}</MenuItem>
                      <MenuItem value={LeadType[2]}>{LeadType[2]}</MenuItem>
                    </Select>
                    {!!errors.lead && (
                      <FormHelperText>{errors.lead}</FormHelperText>
                    )}
                  </>
                )}
              </FormControl>

              {isNewRegistration && (
                <Box my={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.acceptPrivacy}
                        onChange={handleChange}
                        name="acceptPrivacy"
                      />
                    }
                    label={
                      <>
                        I certify that I have thoroughly read and understood the
                        terms and conditions set out in <PrivacyPolicyLink />{" "}
                        and hereby give consent for the Company to collect, use
                        and disclose my personal data in accordance with the{" "}
                        <PrivacyPolicyLink /> above on a voluntary basis.
                      </>
                    }
                  />
                  {!!errors.acceptPrivacy && (
                    <Typography color={"error"} variant={"caption"}>
                      {errors.acceptPrivacy}
                    </Typography>
                  )}
                </Box>
              )}
              <Box my={3}>
                {isNewRegistration && (
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => onNext(RegisterStep.checkEmail)}
                  >
                    Back
                  </Button>
                )}

                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Box>
            </Form>
          </Loading>
        );
      }}
    </Formik>
  );
};

export default PersonalInfoForm;
