import React from "react";
import { AuthenStore } from "./authenStore";
import { SidebarStore } from "./sidebarStore";
import { CourseStore } from "./courseStore";
import { ProfileStore } from "./profileStore";
import { GradeStore } from "./gradeStore";
import { RegisterStore } from "./registerStore";
import { CookiePolicyStore } from "./cookiePolicyStore";

const authenStore = new AuthenStore();
const sidebarStore = new SidebarStore();
const courseStore = new CourseStore(authenStore);
const profileStore = new ProfileStore(authenStore);
const gradeStore = new GradeStore();
const registerStore = new RegisterStore();
const cookiePolicyStore = new CookiePolicyStore();

const stores = {
  authenStore,
  sidebarStore,
  courseStore,
  profileStore,
  gradeStore,
  registerStore,
  cookiePolicyStore,
};

export const StoreContext = React.createContext(stores);

export const StoreContextProvider = ({ children }) => {
  return (
    <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>
  );
};

export const useStores = () => React.useContext(StoreContext);

export const useAuthenStore = () => useStores().authenStore;
export const useSidebarStore = () => useStores().sidebarStore;
export const useCourseStore = () => useStores().courseStore;
export const useProfileStore = () => useStores().profileStore;
export const useGradeStore = () => useStores().gradeStore;
export const useRegisterStore = () => useStores().registerStore;
export const useCookiePolicyStore = () => useStores().cookiePolicyStore;
