import { makeAutoObservable } from "mobx";

export class SidebarStore {
  isOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
