import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  actionButton: {
    marginTop: theme.spacing(2),
  },
  changePasswordContainer: theme.mixins.container,
  successIcon: {
    fontSize: theme.typography.h4.fontSize,
  },
  successContainer: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.colors.success,
  },
}));
