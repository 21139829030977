import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import MainLayout from "../../../Layout/MainLayout";
import useStyle from "./changePasswordPage.style";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import Loading from "../../../shared/components/Loading";
import { CheckCircle } from "@material-ui/icons";
import { useProfileStore } from "../../../store-mobx";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";

const ChangePasswordFormValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().nullable().required("Password is required"),
  newPassword: Yup.string().nullable().required("New password is required"),
  confirmNewPassword: Yup.string()
    .nullable()
    .oneOf([Yup.ref("newPassword"), null], "Passwords don't match")
    .required("Please confirm new password"),
});

const initialState = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const SuccessDisplay = () => {
  const classes = useStyle();
  const history = useHistory();
  return (
    <div className={classes.successContainer}>
      <CheckCircle className={classes.successIcon} color={"inherit"} />
      <Typography color={"inherit"} variant={"body2"}>
        Success
      </Typography>
      <Button onClick={() => history.replace("/manageProfile")}>Ok</Button>
    </div>
  );
};

const ChangePasswordPage = () => {
  const classes = useStyle();
  const [input, setInput] = useState({ ...initialState });
  const profileStore = useProfileStore();
  const {
    isLoading: loading,
    isChangePasswordSuccess: changePasswordSuccess,
    errorMessage,
  } = profileStore;

  const onSubmit = async (values) => {
    const { oldPassword, newPassword } = values;
    setInput(values);
    await profileStore.doChangePassword(oldPassword, newPassword);
  };

  useEffect(() => {
    return function () {
      profileStore.reset();
    };
  }, []);

  return (
    <MainLayout>
      <div className={classes.changePasswordContainer}>
        <Typography variant={"h2"} color={"primary"} align={"center"}>
          Change Password
        </Typography>
        {changePasswordSuccess && <SuccessDisplay />}
        {!changePasswordSuccess && (
          <Formik
            enableReinitialize={true}
            validationSchema={ChangePasswordFormValidationSchema}
            initialValues={{ ...input }}
            onSubmit={onSubmit}
            validateOnChange={false}
          >
            {(formikProps) => {
              const { values, errors, handleChange } = formikProps;
              return (
                <Loading isLoading={loading}>
                  <Form>
                    <TextField
                      fullWidth
                      margin={"normal"}
                      name="oldPassword"
                      type="password"
                      label="Current Password"
                      onChange={handleChange}
                      value={values.oldPassword}
                      error={!!errors.oldPassword}
                      helperText={errors.oldPassword}
                    />
                    <TextField
                      fullWidth
                      margin={"normal"}
                      name="newPassword"
                      type="password"
                      label="New Password"
                      onChange={handleChange}
                      value={values.newPassword}
                      error={!!errors.newPassword}
                      helperText={errors.newPassword}
                    />
                    <TextField
                      fullWidth
                      margin={"normal"}
                      name="confirmNewPassword"
                      type="password"
                      label="Confirm New Password"
                      onChange={handleChange}
                      value={values.confirmNewPassword}
                      error={!!errors.confirmNewPassword}
                      helperText={errors.confirmNewPassword}
                    />
                    {errorMessage && (
                      <Typography color="error">{errorMessage}</Typography>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth={true}
                      color="primary"
                      style={{ marginTop: 15 }}
                    >
                      Change Password
                    </Button>
                  </Form>
                </Loading>
              );
            }}
          </Formik>
        )}
      </div>
    </MainLayout>
  );
};
export default observer(ChangePasswordPage);
