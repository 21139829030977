import React, { useEffect, useState } from "react";
import useStyle from "./registerPage.style";
import MainLayout from "../../../Layout/MainLayout";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CheckEmailForm from "./CheckEmailForm";
import PersonalInfoForm from "./PersonalInfoForm";

import ConfirmationPage from "./ConfirmationPage";
import { transformPersonalInfoFormValuesToPersonalInfo } from "../../personalInfoFormHelper";
import { useGradeStore, useRegisterStore } from "../../../store-mobx";
import { NavigateToLoginPageButton } from "./NavigateToLoginPageButton";
import { observer } from "mobx-react-lite";

export const RegisterStep = {
  checkEmail: 0,
  personalInfo: 1,
  confirmAccount: 2,
};

export const RegisterStepLabels = {
  [RegisterStep.checkEmail]: "Provide Email Account",
  [RegisterStep.personalInfo]: "Personal Information",
  [RegisterStep.confirmAccount]: "Confirm Account",
};

const RegisterPage = () => {
  const classes = useStyle();
  const [activeStep, setActiveStep] = useState(0);
  const gradeStore = useGradeStore();
  const registerStore = useRegisterStore();
  const { isLoading: isGradeLoading, grades } = gradeStore;
  const {
    _isLoading: isRegisterLoading,
    _personalInfo: personalInfo,
    _createStudentAccountSuccess: createStudentAccountSuccess,
  } = registerStore;
  const loading = isRegisterLoading || isGradeLoading;

  const onNext = (nextStep) => {
    setActiveStep(nextStep);
  };

  const onSubmitRegister = async (personalInfoFormValues) => {
    const newPersonalInfo = transformPersonalInfoFormValuesToPersonalInfo(
      personalInfoFormValues,
      grades
    );
    await registerStore.createStudentAccount(newPersonalInfo);
  };

  useEffect(() => {
    (async () => await gradeStore.fetchGrades())();
  }, []);

  useEffect(() => {
    if (createStudentAccountSuccess) {
      setActiveStep(RegisterStep.confirmAccount);
    }
  }, [createStudentAccountSuccess]);

  return (
    <MainLayout>
      <div className={classes.registerContainer}>
        <Typography variant={"h2"} color={"primary"}>
          Account Registration
        </Typography>
        <Stepper activeStep={activeStep} className={classes.steps}>
          <Step>
            <StepLabel>{RegisterStepLabels[RegisterStep.checkEmail]}</StepLabel>
          </Step>
          <Step>
            <StepLabel>
              {RegisterStepLabels[RegisterStep.personalInfo]}
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              {RegisterStepLabels[RegisterStep.confirmAccount]}
            </StepLabel>
          </Step>
        </Stepper>
        {activeStep === RegisterStep.checkEmail && (
          <CheckEmailForm onNext={onNext} />
        )}
        {activeStep === RegisterStep.personalInfo && (
          <PersonalInfoForm
            onNext={onNext}
            isNewRegistration={true}
            onSubmit={onSubmitRegister}
            personalInfo={personalInfo}
            grades={grades}
            loading={loading}
          />
        )}
        {activeStep === RegisterStep.confirmAccount && <ConfirmationPage />}
        {activeStep === RegisterStep.checkEmail && (
          <div className={classes.loginNavigationContainer}>
            <NavigateToLoginPageButton />
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default observer(RegisterPage);
