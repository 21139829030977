import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useRegisterStore } from "../../../store-mobx";
import { observer } from "mobx-react-lite";

const ConfirmationPage = () => {
  const { _personalInfo: personalInfo } = useRegisterStore();
  const history = useHistory();
  return (
    <>
      <Typography>
        Your account login information has been sent to {personalInfo.email}.
      </Typography>
      <Typography>
        Please follow steps in the email to access your account.
      </Typography>
      <Typography>
        If you have trouble accessing your account, please contact
        administrator.
      </Typography>
      <Box my={4}>
        <Button fullWidth onClick={() => history.push("/login")}>
          Login
        </Button>
      </Box>
    </>
  );
};

export default observer(ConfirmationPage);
