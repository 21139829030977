import { makeAutoObservable } from "mobx";
import { AuthApi } from "../api";

export class AuthenStore {
  isLoading = false;
  isLoggedIn = false;
  isLogoutSuccess = false;
  currentUser = null;
  errorMessage = null;

  constructor() {
    makeAutoObservable(this);
  }

  async doCheckIsLoggedIn() {
    this.setIsLoading = true;
    try {
      const { data: currentUser } = await AuthApi.fetchCurrentUserInfo();
      this.setIsLoggedIn = true;
      this.setCurrentUser = currentUser;
    } catch (e) {
      this.reset();
    } finally {
      this.setIsLoading = false;
    }
  }

  async doLogin(username, password) {
    this.setIsLoading = true;
    this.errorMessage = null;
    try {
      await AuthApi.login(username, password);
      const { data: currentUser } = await AuthApi.fetchCurrentUserInfo();
      this.setIsLoggedIn = true;
      this.setCurrentUser = currentUser;
    } catch (e) {
      this.setIsLoggedIn = false;
      this.setErrorMessage = "Invalid username or password.";
    } finally {
      this.setIsLoading = false;
    }
  }

  async doLogout() {
    this.setIsLoading = true;
    try {
      await AuthApi.logout();
      this.setIsLoggedIn = false;
      this.setCurrentUser = null;
    } finally {
      this.setIsLoading = false;
    }
  }

  reset() {
    this.setIsLoading = false;
    this.setIsLoggedIn = false;
    this.setIsLogoutSuccess = false;
    this.setCurrentUser = null;
    this.setErrorMessage = "";
  }

  set setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  set setIsLoggedIn(isLoggedIn) {
    this.isLoggedIn = isLoggedIn;
  }

  set setErrorMessage(errorMessage) {
    this.errorMessage = errorMessage;
  }

  set setCurrentUser(user) {
    this.currentUser = user;
  }

  set setIsLogoutSuccess(isLogoutSuccess) {
    this.isLogoutSuccess = isLogoutSuccess;
  }
}
