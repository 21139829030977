import axios from "axios";
import { hashPassword } from "../shared/util/passwordHasher";

export const login = async (username, password) => {
  const params = new URLSearchParams();
  params.append("username", username);
  params.append("password", await hashPassword(password));
  params.append("rememberMe", "false");
  return await axios.post("/student-api/authenticate", params, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const logout = () => {
  return axios.post("/student-api/authenticate/logout");
};

export const fetchCurrentUserInfo = () => {
  return axios.get("/student-api/profile/me");
};

export const changePassword = async (changePassword) => {
  const request = {
    ...changePassword,
    oldPassword: await hashPassword(changePassword.oldPassword),
    newPassword: await hashPassword(changePassword.newPassword),
  };
  return await axios.post(`/student-api/profile/changePassword`, request);
};

export const checkEmailAvailability = (email) => {
  const params = new URLSearchParams();
  params.append("email", email);
  return axios.get(
    `/student-api/profile/isEmailAvailable?${params.toString()}`
  );
};

export const checkDuplicate = (personalInfo) => {
  const { firstnameEN, lastnameEN, thaiId, passportNo } = personalInfo;
  return axios.post(`/student-api/profile/checkDuplicate`, {
    firstnameEN,
    lastnameEN,
    thaiId,
    passportNo,
  });
};

export const createStudentAccount = (personalInfo) => {
  return axios.post(`/student-api/profile/register`, personalInfo);
};

export const updateStudentAccount = (personalInfo) => {
  return axios.put(`/student-api/profile/updateProfile`, personalInfo);
};
