import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

const LoadingIcon = () => (
  <Box component="div" width={"100%"} textAlign={"center"}>
    <CircularProgress size={100} />
  </Box>
);

export default LoadingIcon;
