import { createTheme, responsiveFontSizes } from "@material-ui/core";
import createPalette from "@material-ui/core/styles/createPalette";
import { grey, red } from "@material-ui/core/colors";
import "typeface-lato";

const colors = {
  success: "#5cb85c",
  warning: "#f8ac59",
  danger: red[500],
  info: "#63b7e6",
  defaultText: "#fff",
  alternateText: grey[900],
  hausPrimary: "#ffcc00",
  backgroundColor: "#fff",
  alternateBackgroundColor: grey[200],
};

const palette = createPalette({
  primary: {
    light: grey[700],
    main: grey[900],
    dark: grey[900],
    contrastText: colors.defaultText,
  },
  secondary: {
    light: colors.hausPrimary,
    main: colors.hausPrimary,
    dark: colors.hausPrimary,
    contrastText: colors.alternateText,
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  background: {
    default: colors.backgroundColor,
  },
  colors: { ...colors },
});

const status = {
  success: {
    backgroundColor: colors.success,
    color: colors.defaultText,
  },
  warning: {
    backgroundColor: colors.warning,
    color: colors.defaultText,
  },
  danger: {
    backgroundColor: colors.danger,
    color: colors.defaultText,
  },
  info: {
    backgroundColor: colors.info,
    color: colors.defaultText,
  },
};

const theme = createTheme({
  palette,
  status: { ...status },
  props: {
    MuiTextField: {
      margin: "dense",
      autoComplete: "off",
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText,
        fontWeight: 900,
      },
    },
    MuiButton: {
      root: {
        fontWeight: 900,
      },
    },
  },
  typography: {
    headline: {
      fontWeight: 900,
    },
    fontFamily: [
      "Lato",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

theme.mixins.container = {
  marginLeft: "auto",
  marginRight: "auto",
  [theme.breakpoints.up("md")]: {
    width: 800,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
};

export default responsiveFontSizes(theme);
