import React from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withWidth,
} from "@material-ui/core";
import { isWidthUp } from "@material-ui/core/withWidth";
import MainLayout from "../../Layout/MainLayout";
import useStyle from "./mainPage.style";

const DenseTableCell = withWidth()(({ width, ...props }) => {
  const padding = isWidthUp("md", width) ? "default" : "dense";
  return <TableCell {...props} padding={padding} />;
});

const SubHeaderCell = (props) => {
  const classes = useStyle();
  return <DenseTableCell className={classes.subHeaderCell} {...props} />;
};

const MainPage = () => {
  const classes = useStyle();

  return (
    <MainLayout>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant={"h3"} gutterBottom={true}>
            Updates - Exclusive for Haus Students
          </Typography>
          <Typography variant={"body2"}>
            Join us for{" "}
            <strong>Haus Personal Statement Course by "Kru Eam"</strong> and{" "}
            <strong>Haus SAT Intensive Course by "Kru Jap"</strong>
          </Typography>
          <Typography variant={"h4"} className={classes.sectionTitle}>
            Personal Statement Course for UK University Application (Kru Eam)
          </Typography>
          <Typography>
            Length: 10 Hours (2 Hours per session / 5 sessions in total)
          </Typography>
          <Typography gutterBottom={true}>
            Guarantee: A Complete Personal Statement for Final UCAS Submission
          </Typography>
          <Paper className={classes.paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <DenseTableCell>
                    <Typography
                      style={{ fontSize: "inherit", fontWeight: "inherit" }}
                    >
                      Students per Class
                    </Typography>
                  </DenseTableCell>
                  <DenseTableCell>Session Details</DenseTableCell>
                  <DenseTableCell colSpan={2}>Session Breakdown</DenseTableCell>
                  <DenseTableCell>
                    Total Course Fees/ person (Baht)
                  </DenseTableCell>
                </TableRow>
                <TableRow className={classes.subHeaderRow}>
                  <SubHeaderCell />
                  <SubHeaderCell />
                  <SubHeaderCell>Group</SubHeaderCell>
                  <SubHeaderCell>Private</SubHeaderCell>
                  <SubHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <DenseTableCell>1</DenseTableCell>
                  <DenseTableCell>
                    <Typography className={classes.bold}>
                      Private Classes
                    </Typography>
                    <Typography>
                      Suitable for Oxbridge and Medicine Applications
                    </Typography>
                  </DenseTableCell>
                  <DenseTableCell>None</DenseTableCell>
                  <DenseTableCell>5(10 Hours)</DenseTableCell>
                  <DenseTableCell>30,000</DenseTableCell>
                </TableRow>
                <TableRow>
                  <DenseTableCell>2</DenseTableCell>
                  <DenseTableCell>
                    <Typography className={classes.bold}>
                      Group Classes + Private Classes
                    </Typography>
                    <Typography>
                      Suitable for Students who prefer a brainstorming process
                      from like-minded classmates
                    </Typography>
                  </DenseTableCell>
                  <DenseTableCell>3 (6 Hours)</DenseTableCell>
                  <DenseTableCell>
                    <Typography>2 (4 Hours)</Typography>
                    <Typography>To Be Arranged Individually</Typography>
                  </DenseTableCell>
                  <DenseTableCell>20,000</DenseTableCell>
                </TableRow>
                <TableRow>
                  <DenseTableCell>3</DenseTableCell>
                  <DenseTableCell>
                    <Typography className={classes.bold}>
                      Group Classes + Private Classes
                    </Typography>
                    <Typography>
                      Suitable for Students who prefer a brainstorming process
                      from like-minded classmates
                    </Typography>
                  </DenseTableCell>
                  <DenseTableCell>3 (6 Hours)</DenseTableCell>
                  <DenseTableCell>
                    <Typography>2 (8 Hours)</Typography>
                    <Typography variant={"body2"}>
                      To Be Arranged Individually
                    </Typography>
                  </DenseTableCell>
                  <DenseTableCell>18,000</DenseTableCell>
                </TableRow>
                <TableRow>
                  <DenseTableCell>4</DenseTableCell>
                  <DenseTableCell>
                    <Typography className={classes.bold}>
                      Group Classes + Private Classes
                    </Typography>
                    <Typography>
                      Suitable for Students who prefer a brainstorming process
                      from like-minded classmates
                    </Typography>
                  </DenseTableCell>
                  <DenseTableCell>3(6 Hours)</DenseTableCell>
                  <DenseTableCell>
                    <Typography>2(8 Hours)</Typography>
                    <Typography>To Be Arranged Individually</Typography>
                  </DenseTableCell>
                  <DenseTableCell>15,000</DenseTableCell>
                </TableRow>
                <TableRow>
                  <DenseTableCell>5</DenseTableCell>
                  <DenseTableCell>
                    <Typography className={classes.bold}>
                      Group Classes + Private Classes
                    </Typography>
                    <Typography>
                      Suitable for Students who prefer a brainstorming process
                      from like-minded classmates
                    </Typography>
                  </DenseTableCell>
                  <DenseTableCell>3(6 Hours)</DenseTableCell>
                  <DenseTableCell>
                    <Typography>2(8 Hours)</Typography>
                    <Typography>To Be Arranged Individually</Typography>
                  </DenseTableCell>
                  <DenseTableCell>13,000</DenseTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>

          <Typography variant={"h4"} className={classes.sectionTitle}>
            SAT English Intensive Course (Kru Jap)
          </Typography>
          <Typography gutterBottom={true}>
            Encapsulating everything you need to know to maximise the scores
            right before the test
          </Typography>
          <Paper className={classes.paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <DenseTableCell>
                    <Typography
                      style={{ fontSize: "inherit", fontWeight: "inherit" }}
                    >
                      Students per Class
                    </Typography>
                  </DenseTableCell>
                  <DenseTableCell>Course Duration</DenseTableCell>
                  <DenseTableCell colSpan={3}>Content Breakdown</DenseTableCell>
                  <DenseTableCell>Total Course Fees (Baht)</DenseTableCell>
                </TableRow>
                <TableRow className={classes.subHeaderRow}>
                  <SubHeaderCell />
                  <SubHeaderCell />
                  <SubHeaderCell>Reading Part(10 Hours)</SubHeaderCell>
                  <SubHeaderCell>Writing Part(10 Hours)</SubHeaderCell>
                  <SubHeaderCell>Essay Part</SubHeaderCell>
                  <SubHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <DenseTableCell>2 - 5</DenseTableCell>
                  <DenseTableCell>
                    <Typography>20 Hours</Typography>
                    <Typography>(Within 2 weeks)</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>- Informative Article</Typography>
                    <Typography>- Contemporary Issues</Typography>
                    <Typography>- Narrative Extract / Biography</Typography>
                    <Typography>- Scientific Research</Typography>
                    <Typography>- Speech</Typography>
                    <Typography>- Compare/Contrast 2 Passages</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>- Sentence Structure</Typography>
                    <Typography>- Relative Clauses</Typography>
                    <Typography>- Present and Past Participle</Typography>
                    <Typography>- Connectors</Typography>
                    <Typography>- Tenses</Typography>
                    <Typography>- Word Forms</Typography>
                    <Typography>- Subject - Verb Agreement</Typography>
                    <Typography>- Parallel Structure / Inversions</Typography>
                    <Typography>- Other complex grammars</Typography>
                  </DenseTableCell>
                  <DenseTableCell>N/A</DenseTableCell>
                  <DenseTableCell>16,000</DenseTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>

          <Typography variant={"h4"} className={classes.sectionTitle}>
            SAT English Private Course (Kru Jap)
          </Typography>
          <Paper className={classes.paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <DenseTableCell>
                    <Typography
                      style={{ fontSize: "inherit", fontWeight: "inherit" }}
                    >
                      Pre-Test Scores
                    </Typography>
                  </DenseTableCell>
                  <DenseTableCell>Suggested Course Duration</DenseTableCell>
                  <DenseTableCell colSpan={3}>Details</DenseTableCell>
                  <DenseTableCell>Fees per Hour (Baht)</DenseTableCell>
                </TableRow>
                <TableRow className={classes.subHeaderRow}>
                  <SubHeaderCell />
                  <SubHeaderCell />
                  <SubHeaderCell>Reading Part</SubHeaderCell>
                  <SubHeaderCell>Writing Part</SubHeaderCell>
                  <SubHeaderCell>Essay Part</SubHeaderCell>
                  <SubHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <DenseTableCell> > 600</DenseTableCell>
                  <DenseTableCell>
                    <Typography>20 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>8 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>8 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>4 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>1,200</Typography>
                  </DenseTableCell>
                </TableRow>
                <TableRow>
                  <DenseTableCell>550 - 590</DenseTableCell>
                  <DenseTableCell>
                    <Typography>30 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>12 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>12 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>6 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>1,200</Typography>
                  </DenseTableCell>
                </TableRow>
                <TableRow>
                  <DenseTableCell>490 - 540</DenseTableCell>
                  <DenseTableCell>
                    <Typography>40 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>16 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>16 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>8 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>1,200</Typography>
                  </DenseTableCell>
                </TableRow>
                <TableRow>
                  <DenseTableCell>400 - 490</DenseTableCell>
                  <DenseTableCell>
                    <Typography>60 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>30 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>30 Hours</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>-</Typography>
                  </DenseTableCell>
                  <DenseTableCell>
                    <Typography>1,200</Typography>
                  </DenseTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default MainPage;
