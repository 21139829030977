import React from "react";
import { makeStyles } from "@material-ui/core";
import TopBar from "../shared/components/TopBar";
import Footer from "../shared/components/Footer";

const useStyles = makeStyles((theme) => ({
  mainPanel: {
    overflow: "auto",
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
  },
  content: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)",
  },
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    background: theme.palette.background.paper,
  },
  map: {
    marginTop: "70px",
  },
}));

const MainLayout = (props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      <TopBar />
      <div className={classes.mainPanel}>
        <div className={classes.content}>
          <div className={classes.container}>{children}</div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default MainLayout;
