import React, { useEffect, useState } from "react";
import MainLayout from "../../../Layout/MainLayout";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Loading from "../../../shared/components/Loading";
import PersonalInfoForm from "../RegisterPage/PersonalInfoForm";
import AlertSnackbar from "../../../shared/components/AlertSnackbar";
import { transformPersonalInfoFormValuesToPersonalInfo } from "../../personalInfoFormHelper";
import { useGradeStore, useProfileStore } from "../../../store-mobx";
import { observer } from "mobx-react-lite";

const useStyle = makeStyles((theme) => ({
  root: theme.mixins.container,
}));

const ManageProfilePage = () => {
  const classes = useStyle();
  const gradeStore = useGradeStore();
  const profileStore = useProfileStore();
  const {
    isLoading: isProfileLoading,
    isUpdating,
    isUpdateProfileSuccess,
    errorMessage: updateErrorMessage,
    personalInfo,
  } = profileStore;
  const { isLoading: isLoadingGrades, grades } = gradeStore;

  const [openAlert, setOpenAlert] = useState(false);
  const [localPersonalInfo, setLocalPersonalInfo] = useState(null);

  useEffect(() => {
    (async () => {
      await gradeStore.fetchGrades();
      await profileStore.getPersonalInfo();
    })();
    return function cleanUp() {
      gradeStore.reset();
      profileStore.reset();
    };
  }, []);

  useEffect(() => {
    setOpenAlert(isUpdateProfileSuccess);
  }, [setOpenAlert, isUpdateProfileSuccess]);

  useEffect(() => {
    setLocalPersonalInfo(personalInfo);
  }, [personalInfo, setLocalPersonalInfo]);

  const onSubmit = async (personalInfoFormValues) => {
    const newPersonalInfo = transformPersonalInfoFormValuesToPersonalInfo(
      personalInfoFormValues,
      grades
    );

    await profileStore.updatePersonalInfo({
      ...newPersonalInfo,
      id: personalInfo.id,
    });
  };

  const loading = isLoadingGrades || isProfileLoading || isUpdating;

  return (
    <MainLayout>
      <div className={classes.root}>
        <Typography variant={"h2"} color={"primary"}>
          Profile
        </Typography>
        <Loading isLoading={loading}>
          <PersonalInfoForm
            personalInfo={localPersonalInfo}
            onSubmit={onSubmit}
            grades={grades}
            loading={loading}
            isNewRegistration={false}
          />
          {updateErrorMessage && (
            <Typography variant={"h5"} color={"error"}>
              {updateErrorMessage}
            </Typography>
          )}
          <AlertSnackbar
            variant={"success"}
            message={"Success!"}
            open={openAlert}
            handleClose={() => setOpenAlert(false)}
          />
        </Loading>
      </div>
    </MainLayout>
  );
};

export default observer(ManageProfilePage);
