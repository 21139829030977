import moment from "moment";
import axios from "axios";

const timestampFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";

export const listClassesByStudentId = (studentId, filter = {}) => {
  const defaultFilter = Object.assign(
    {
      startDate: moment().format(timestampFormat),
      endDate: moment().add(30, "days").format(timestampFormat),
      sunday: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      includeReserve: false,
      onlyReserve: false,
      page: 0,
      size: 10,
    },
    filter
  );

  const params = new URLSearchParams();
  Object.entries(defaultFilter).forEach(([key, value]) => {
    params.append(key, value);
  });
  return axios.get(`/student-api/courses?${params.toString()}`);
};

export const listClassesForCalendarByStudentId = (studentId, filter = {}) => {
  const defaultFilter = Object.assign(
    {
      startDate: moment().format(timestampFormat),
      endDate: moment().add(30, "days").format(timestampFormat),
      sunday: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      includeReserve: false,
      onlyReserve: false,
    },
    filter
  );

  const params = new URLSearchParams();
  Object.entries(defaultFilter).forEach(([key, value]) => {
    params.append(key, value);
  });
  return axios.get(`/student-api/courses/timetable?${params.toString()}`);
};
