import React from "react";
import { Fade, Grid } from "@material-ui/core";

const CenterBox = ({ children, ...rest }) => (
  <Fade in timeout={400}>
    <Grid
      container
      alignItems="center"
      direction="row"
      justifyContent="center"
      style={{
        height: "calc(100vh)",
      }}
      {...rest}
    >
      {children}
    </Grid>
  </Fade>
);

export default CenterBox;
