import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import CustomTableCell from "./CustomTableCell";
import CourseStatus from "../CourseStatus";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Loading from "../../../shared/components/Loading";
import { useCourseStore } from "../../../store-mobx";
import { observer } from "mobx-react-lite";

const displayDateFormat = "ddd, DD MMM YYYY";
const useStyle = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    overflowX: "scroll",
  },
  alternateRow: {
    backgroundColor: theme.palette.colors.alternateBackgroundColor,
  },
  cancelledCourse: {
    color: theme.palette.colors.danger,
  },
  table: {
    minWidth: 700,
  },
}));

const CourseTable = observer((props) => {
  const classes = useStyle();
  const { filter: dateFilter } = props;
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    ...dateFilter,
  });
  const courseStore = useCourseStore();
  const { isLoading, courses } = courseStore;
  const { page, size } = filter;

  useEffect(() => {
    setFilter((filter) => ({ ...filter, ...dateFilter }));
  }, [dateFilter, setFilter]);

  useEffect(() => {
    (async () => {
      await courseStore.fetchCourses(filter);
    })();
  }, [filter]);

  const { totalItems = 0 } = courses.length === 0 ? 0 : courses[0].paging;

  const handleChangePage = (event, page) => {
    const newFilter = { ...filter, page };
    setFilter(newFilter);
  };

  const handleChangeRowsPerPage = (event) => {
    const newFilter = { ...filter, size: event.target.value };
    setFilter(newFilter);
  };

  const arrayOfCourseDate = useMemo(
    () =>
      Array.from(
        new Set(
          courses.map((c) => moment(c.startTime).format(displayDateFormat))
        )
      ),
    [courses]
  );

  return (
    <Loading isLoading={isLoading}>
      <Paper className={classes.paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Period</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Teacher</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map((row) => {
              const { level, startTime, endTime, teacher, status } = row;
              const courseDate = moment(startTime).format(displayDateFormat);
              const isCancelled = status.indexOf("CC_") === 0;
              const rowClass =
                arrayOfCourseDate.indexOf(courseDate) % 2 === 1
                  ? classes.alternateRow
                  : "";

              return (
                <TableRow key={row.id} className={rowClass}>
                  <CustomTableCell isCancelled={isCancelled}>
                    {`${courseDate}`}
                  </CustomTableCell>
                  <CustomTableCell isCancelled={isCancelled}>
                    {`${moment(startTime).format("HH:mm")} - ${moment(
                      endTime
                    ).format("HH:mm")}`}
                  </CustomTableCell>
                  <CustomTableCell isCancelled={isCancelled}>
                    {`${level.subjectName} - ${level.name}`}
                  </CustomTableCell>
                  <CustomTableCell isCancelled={isCancelled}>
                    {`${teacher.nicknameEN} (${teacher.firstnameEN})`}
                  </CustomTableCell>
                  <TableCell>
                    <CourseStatus status={status} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalItems}
          rowsPerPage={size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Loading>
  );
});

CourseTable.propTypes = {
  filter: PropTypes.object.isRequired,
};

export default CourseTable;
