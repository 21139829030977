import React from "react";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Button,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { useAuthenStore, useSidebarStore } from "../../store-mobx";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginLeft: -12,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.colors.hausPrimary,
  },
  title: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  logo: {
    height: 48,
    marginLeft: -24,
    marginTop: -4,
    display: "block",
  },
}));

const TopBar = observer(() => {
  const classes = useStyles();
  const authenStore = useAuthenStore();
  const { currentUser, isLoggedIn } = authenStore;
  const sidebarStore = useSidebarStore();
  const history = useHistory();

  const suppressNavigation =
    !!currentUser &&
    (currentUser.forceChangePassword || currentUser.requireProfileInput);
  const navigate = (destination) => {
    if (suppressNavigation) {
      return;
    }

    history.push(destination);
  };

  const renderLoginLogoutButton = () => {
    if (suppressNavigation) {
      return renderLoginButton();
    }

    return isLoggedIn ? renderLogoutButton() : renderLoginButton();
  };

  const renderLoginButton = () => (
    <Button onClick={() => history.push("/login")} color={"inherit"}>
      Login
    </Button>
  );

  const renderLogoutButton = () => (
    <Button onClick={() => authenStore.doLogout()} color={"inherit"}>
      Logout
    </Button>
  );

  return (
    <AppBar className={classes.appBar} position="absolute" color="primary">
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          onClick={() => {
            if (!suppressNavigation) {
              sidebarStore.toggle();
            }
          }}
        >
          <MenuIcon />
        </IconButton>
        <img
          alt={"Haus Academy"}
          src={"/images/haus-web-logo-trans.png"}
          className={classes.logo}
          onClick={() => navigate("/")}
        />
        <Typography className={classes.title} />
        {renderLoginLogoutButton()}
      </Toolbar>
    </AppBar>
  );
});

export default TopBar;
