import React from "react";
import CenterBox from "../../../shared/components/CenterBox";
import { Button, Typography } from "@material-ui/core";
import LoginForm from "./LoginForm";
import { useHistory } from "react-router-dom";
import moment from "moment";
import useStyles from "./loginPage.style";

const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <CenterBox className={classes.background}>
      <div className={classes.loginBoxContainer}>
        <img
          src={"/images/hauslogo_trans.png"}
          className={classes.logo}
          alt={"Haus Academy"}
        />
        <Typography color="primary" align="center" variant="h3">
          Student Portal
        </Typography>
        <LoginForm />
        <Button
          fullWidth
          color={"primary"}
          variant={"contained"}
          size={"large"}
          onClick={() => history.push("/register")}
          className={classes.actionButton}
        >
          Register
        </Button>
        <div className={classes.copyRight}>
          <Typography>
            House Academy © 2015-{moment().format("YYYY")}
          </Typography>
        </div>
      </div>
    </CenterBox>
  );
};

export default LoginPage;
