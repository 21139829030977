import React from "react";
import { Link } from "@material-ui/core";
import MainLayout from "../Layout/MainLayout";
import { PrivacyPolicyLink } from "../shared/components/PrivacyPolicyLink";

export const CookiePolicyPage = () => {
  return (
    <MainLayout>
      <h1>Cookies Policy</h1>
      <p>
        Our Site uses cookies to distinguish you from other users of our
        website. This helps us to provide you with a good experience when you
        use the website and also allows us to improve our website.
      </p>
      <p>
        The purpose of this policy is to provide you as a user of the Haus
        Academy’s services clear and accessible information about the cookies
        used by Haus Academy, as well as, the role that cookies help us provide
        you the best possible experience and the choices you have in relation to
        your cookie settings.
      </p>
      <h2>1. What are cookies?</h2>
      <p>
        A cookie is a small piece of file that is stored on your browser or the
        hard drive of your computer or mobile devices. The purpose of which is
        to store the various information and settings such as recording
        information regarding language setting preference of browser on your
        devices, recording your current access status so that you can access the
        website uninterrupted. Additionally, cookie collects information on
        history of visited websites of your preference in the form of file. On
        this basis, a cookie does not pose a danger to your device and the
        content in the cookie may only be viewed or read via website which
        created it. You are enable to accept or reject cookies by configuration
        the cookies setting in your browser, please see the help menu on your
        browser.
      </p>
      <p>
        Information we receive through cookies and other similar technologies
        may also include your IP address, mobile advertising ID, browser
        features, device attributes, unique device ID, operating system,
        language settings, URL references, logs of behavior relating to your
        site visited. For instance, the content that the user visits or is
        interested in while visiting such website, including the date and time
        the user access or use the website to connect our mobile application and
        tablet, we may also use a similar automated method.{" "}
      </p>
      <p>
        Our <PrivacyPolicyLink /> sets out all details about what other
        information we collect and how we use your personal information.{" "}
      </p>
      <h2>2. Benefits of cookies</h2>
      <p>
        Cookies could provide us the information with regard to the parts of our
        website or application visited by a user in order for us to provide you
        with a better website and application experience being consistent with
        your needs. For instance, cookies can recognize you and remember
        material information, which could facilitate the use of our website e.g.
        remembering your setting preference. In addition, using cookies to
        record the initial settings of the website will allow you to access the
        website with such initially determined settings on every occasion of
        use, except where cookies are deleted. In the latter case, all the
        settings will be restored to the default settings.{" "}
      </p>
      <h2>3. Type of cookies</h2>
      <ul>
        <li>
          <strong>Strictly Necessary Cookies:</strong> These are cookies that
          are required for the operation of our website and necessary to enable
          us to provide the services of Haus Academy per your requested. They
          include, for example, cookies that enable you to log into our secure
          website.
        </li>
        <li>
          <strong>Analytical cookies / performance:</strong> These allow us to
          recognize and count the number of visitors to our website, as well as,
          help us to know the behavior of visitors when they are using it. In
          addition, these cookies help improve the working quality of our
          website to be better and more appropriate. It also gathers statistical
          data relating to methods and behavior of website’s traffic, which
          helps us to improve the way our website works, by ensuring that users
          are finding what they are looking for easily. And help us understand
          the interests of our users and measure the effectiveness of our
          advertisements.
        </li>
        <li>
          <strong>Functional cookies:</strong> These cookies are used to
          recognize the user. If users revisit our website, these cookies enable
          us to personalize our content pursuant to your interests, and remember
          your setting preferences. We may also share such information with
          third parties for such purposes.
        </li>
        <li>
          <strong>Advertising and marketing cookies:</strong> These cookies will
          remember your setting preferences in using the website. It also can be
          used as a supplement information to personalize your website in order
          to offer you advertisements that are as relevant to you as possible,
          for example, by selecting interest-based advertisements for you, or
          preventing or limiting the number of times you see the same
          advertisement to help measure the effectiveness of advertisements.
        </li>
      </ul>
      <h2>4. How long are cookies stored on my device?</h2>
      <p>There are two types of cookies, which could be stored: </p>
      <ul>
        <li>
          <strong>Session cookies</strong> which are automatically deleted when
          you close your browser
        </li>
        <li>
          <strong>Persistent cookies</strong> which remain on your computer
          until they are deleted or expire. Persistent cookies can keep your
          user preferences to help make future browsing easier and more
          relevant.
        </li>
      </ul>
      <h2>5. Cookie Configuration </h2>
      <p>
        You can choose to reject or block all or specific types of cookies set
        by virtue of your visit to the website by configuring your preferences
        on your browser settings. Please note that most browsers automatically
        accept cookies. Therefore, if you do not wish cookies to be used, you
        may need to actively delete or block the cookies. You may also visit
        www.allaboutcookies.org for details on how to delete or reject cookies
        and for further information on cookies generally.
      </p>
      <p>
        Please note that if you reject the use of cookies, you will still be
        able to visit our websites but some of the functions may not work
        correctly. By using our website without deleting or rejecting some or
        all cookies, you agree that we can place those cookies that you have not
        deleted or rejected on your device.
      </p>
      <p>
        You can manage and adjust cookie default setting by blocking cookie from
        the browser you use to visit the website. Please follow the link below
        to learn how to manage your cookie settings.
      </p>
      <ul>
        <li>
          Cookies settings in{" "}
          <Link
            href={
              "https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666"
            }
            color={"textSecondary"}
          >
            Chrome
          </Link>
        </li>
        <li>
          Cookies settings in{" "}
          <Link
            href={
              "https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies"
            }
            color={"textSecondary"}
          >
            Firefox
          </Link>
        </li>
        <li>
          Cookies settings in{" "}
          <Link
            href={
              "https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
            }
            color={"textSecondary"}
          >
            Safari and iOS
          </Link>
        </li>
        <li>
          Cookies settings in{" "}
          <Link
            href={
              "https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10"
            }
            color={"textSecondary"}
          >
            Internet Explorer
          </Link>
        </li>
      </ul>
      <h2>6. Cookies policy does not cover third party websites</h2>
      <p>
        Please note that this cookies policy does not apply to, and we are not
        responsible for, the privacy practices of third party websites which may
        be linked to this website.
      </p>
      <h2>7. How to contact us?</h2>
      <p>
        If you have any queries regarding this Cookies Policy, please contact us
        at <a href={"mailto:info@hausacademy.com"}>info@hausacademy.com</a>
      </p>
      <h2>8. Changes to the Cookies Policy</h2>
      <p>
        We may update this Cookies Policy and we would encourage you to review
        the policy from time to time to stay informed of how we are using
        cookies.
      </p>
      <p>This Cookies Policy was last updated on 1 May 2021</p>
    </MainLayout>
  );
};
