import React, { useState } from "react";
import moment from "moment";
import MainLayout from "../../../Layout/MainLayout";
import Typography from "@material-ui/core/Typography";
import { DatePicker } from "@material-ui/pickers";
import CourseTable from "./CourseTable";

import CourseCalendar from "./CourseCalendar";
import { Box, ButtonGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const datepickerFormat = "DD/MM/YYYY";
const timestampFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
const maxDate = moment().add(6, "months").format(timestampFormat);

const asParsableDate = (momentDate) => {
  return momentDate.format(timestampFormat);
};

const initialFilter = {
  startDate: asParsableDate(moment().subtract(30, "days")),
  endDate: asParsableDate(moment().add(60, "days")),
};

const MODE = {
  TABLE: "TABLE",
  CALENDAR: "CALENDAR",
};

const TimetablePage = () => {
  const [filter, setFilter] = useState(initialFilter);
  const [mode, setMode] = useState(MODE.TABLE);

  return (
    <MainLayout>
      <Typography variant={"h3"}>Classes</Typography>
      <Box marginY={2}>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button
            variant={mode === MODE.TABLE ? "contained" : "outlined"}
            onClick={() => setMode(MODE.TABLE)}
          >
            View as table
          </Button>
          <Button
            variant={mode === MODE.CALENDAR ? "contained" : "outlined"}
            onClick={() => setMode(MODE.CALENDAR)}
          >
            View as Calendar
          </Button>
        </ButtonGroup>
      </Box>
      <Box display={"inline"} marginRight={2}>
        <DatePicker
          id="from-date-picker"
          label="From"
          minDate={initialFilter.startDate}
          views={["year", "month", "date"]}
          format={datepickerFormat}
          value={filter.startDate}
          onChange={(selectedDate) => {
            setFilter({
              ...filter,
              startDate: asParsableDate(selectedDate),
            });
          }}
          animateYearScrolling
        />
      </Box>
      <Box display={"inline"}>
        <DatePicker
          id="to-date-picker"
          label="To"
          minDate={filter.startDate}
          views={["year", "month", "date"]}
          format={datepickerFormat}
          value={filter.endDate}
          maxDate={maxDate}
          onChange={(selectedDate) => {
            setFilter({
              ...filter,
              endDate: asParsableDate(selectedDate),
            });
          }}
          animateYearScrolling
        />
      </Box>

      {mode === MODE.TABLE ? (
        <CourseTable filter={filter} />
      ) : (
        <CourseCalendar filter={filter} />
      )}
    </MainLayout>
  );
};

export default TimetablePage;
