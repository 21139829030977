import moment from "moment";
import { IdType } from "./constants";

export const transformPersonalInfoFormValuesToPersonalInfo = (
  personalInfoFormValues,
  grades
) => {
  const {
    firstnameEN,
    lastnameEN,
    nicknameEN,
    firstnameTH,
    lastnameTH,
    nicknameTH,
    mobile,
    parentPhone,
    parentRelation,
    streetAddress,
    city,
    state,
    postalCode,
    school,
    birthdate,
    idType,
    thaiId,
    passportNo,
    passportIssuingCountry,
    gradeId,
    lead,
    email,
  } = personalInfoFormValues;

  const newPersonalInfo = {
    email,
    firstnameEN,
    lastnameEN,
    nicknameEN,
    firstnameTH,
    lastnameTH,
    nicknameTH,
    mobile,
    parentPhone,
    parentRelation,
    streetAddress,
    city,
    state,
    postalCode,
    lead,
    birthdate: birthdate.toDate(),
    schools: [{ name: school }],
  };

  if (idType === IdType.thaiId) {
    newPersonalInfo.thaiId = thaiId;
  } else if (idType === IdType.passportNo) {
    newPersonalInfo.passportNo = passportNo;
    newPersonalInfo.passportIssuingCountry = passportIssuingCountry;
  }

  const grade = grades.find((g) => gradeId && gradeId === g.id);
  if (!!grade) {
    newPersonalInfo.grade = grade;
  }

  return newPersonalInfo;
};

export const transformPersonalInfoToFormValues = (personalInfo) => {
  if (personalInfo === null) {
    return {};
  }
  const formValues = {
    ...personalInfo,
  };

  delete formValues.grade;
  delete formValues.schools;

  const { schools, grade, birthdate } = personalInfo;
  formValues.school =
    schools && schools.length > 0 ? schools[schools.length - 1].name : "";
  formValues.gradeId = grade ? grade.id : 0;
  const birthdateAsMoment = moment(birthdate);
  formValues.birthdate = birthdateAsMoment.isValid()
    ? birthdateAsMoment
    : moment();
  return formValues;
};
