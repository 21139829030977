import { makeAutoObservable } from "mobx";
import { GradeApi } from "../api";

export class GradeStore {
  isLoading = false;
  grades = [];

  constructor() {
    makeAutoObservable(this);
  }

  async fetchGrades() {
    this.setIsLoading = true;
    try {
      const { data } = await GradeApi.getGrades();
      this.setGrades = data;
    } catch (e) {
      console.error(e);
    } finally {
      this.setIsLoading = false;
    }
  }

  reset() {
    this.setGrades = [];
    this.setIsLoading = false;
  }

  set setGrades(grades) {
    this.grades = grades;
  }

  set setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }
}
