import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import { FormLabel, TextField, Typography } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Loading from "../../../shared/components/Loading";
import { RegisterStep } from "./RegisterPage";
import { useRegisterStore } from "../../../store-mobx";
import { observer } from "mobx-react-lite";
import { IdType } from "../../constants";

const CheckEmailFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .nullable(true)
    .required("Email is required")
    .email("Please provide valid email"),
  firstnameEN: Yup.string().nullable(true).required("First name is required"),
  lastnameEN: Yup.string().nullable(true).required("Last name is required"),
  idType: Yup.string().nullable(true).required("Id Type is required"),
  thaiId: Yup.string()
    .nullable(true)
    .when("idType", {
      is: IdType.thaiId,
      then: Yup.string().min(13).max(13).required("Thai Id is required."),
    }),
  passportNo: Yup.string()
    .nullable(true)
    .when("idType", {
      is: IdType.passportNo,
      then: Yup.string().required("Passport No is required."),
    }),
});

const CheckEmailForm = (props) => {
  const { onNext } = props;
  const registerStore = useRegisterStore();
  const {
    _isLoading: loading,
    _personalInfo: personalInfo,
    _errorMessage: errorMessage,
    _checkEmailAndNameSuccess: checkEmailAndNameSuccess,
  } = registerStore;

  const onSubmit = async (values) => {
    await registerStore.checkEmailAndName(values);
  };

  useEffect(() => {
    if (checkEmailAndNameSuccess) {
      onNext(RegisterStep.personalInfo);
    }
  }, [checkEmailAndNameSuccess, onNext]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={personalInfo}
      onSubmit={onSubmit}
      validateOnChange={false}
      validationSchema={CheckEmailFormValidationSchema}
    >
      {(formikProps) => {
        const { values, errors, handleChange } = formikProps;
        return (
          <Loading isLoading={loading}>
            <Form>
              <TextField
                fullWidth
                margin={"normal"}
                autoComplete={"off"}
                required
                label={"Email"}
                onChange={handleChange}
                name={"email"}
                value={values.email}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                fullWidth
                margin={"normal"}
                autoComplete={"off"}
                required
                label={"First Name(Eng)"}
                onChange={handleChange}
                name={"firstnameEN"}
                value={values.firstnameEN}
                error={!!errors.firstnameEN}
                helperText={errors.firstnameEN}
              />
              <TextField
                fullWidth
                autoComplete={"off"}
                margin={"normal"}
                required
                label={"Last Name(Eng)"}
                onChange={handleChange}
                name={"lastnameEN"}
                value={values.lastnameEN}
                error={!!errors.lastnameEN}
                helperText={errors.lastnameEN}
              />
              <FormLabel required={true} component={"h2"}>
                Thai ID Card No. or Passport No.
              </FormLabel>
              <RadioGroup
                name="idType"
                value={values.idType}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={IdType.thaiId}
                  control={<Radio />}
                  label="Thai Id"
                />
                <FormControlLabel
                  value={IdType.passportNo}
                  control={<Radio />}
                  label="Passport No"
                />
              </RadioGroup>
              {values.idType === IdType.thaiId && (
                <TextField
                  fullWidth
                  autoComplete={"off"}
                  margin={"normal"}
                  label={"Thai Id"}
                  onChange={handleChange}
                  name={"thaiId"}
                  value={values.thaiId}
                  error={!!errors.thaiId}
                  helperText={errors.thaiId}
                />
              )}
              {values.idType === IdType.passportNo && (
                <TextField
                  fullWidth
                  autoComplete={"off"}
                  margin={"normal"}
                  label={"Passport No"}
                  onChange={handleChange}
                  name={"passportNo"}
                  value={values.passportNo}
                  error={!!errors.passportNo}
                  helperText={errors.passportNo}
                />
              )}
              {!!errorMessage && (
                <Typography color={"error"}>{errorMessage}</Typography>
              )}
              <Button type={"submit"} color={"primary"} variant={"contained"}>
                Continue
              </Button>
            </Form>
          </Loading>
        );
      }}
    </Formik>
  );
};

export default observer(CheckEmailForm);
