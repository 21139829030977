import { makeAutoObservable } from "mobx";
import { AuthApi } from "../api";
import { IdType } from "../auth/constants";

const initialPersonalInfo = {
  email: "",
  firstnameEN: "",
  lastnameEN: "",
  thaiId: "",
  passportNo: "",
  gradeId: 0,
  lead: "",
  idType: IdType.thaiId,
};

export class RegisterStore {
  _isLoading = false;
  _personalInfo = { ...initialPersonalInfo };
  _errorMessage = null;
  _checkEmailAndNameSuccess = false;
  _createStudentAccountSuccess = false;

  constructor() {
    makeAutoObservable(this);
  }

  async checkEmailAndName(personalInfo) {
    this.isLoading = true;
    this.personalInfo = personalInfo;
    const { email } = personalInfo;
    try {
      await AuthApi.checkEmailAvailability(email);
      await AuthApi.checkDuplicate(personalInfo);
      this.checkEmailAndNameSuccess = true;
    } catch (e) {
      let errorMessage = "System not available, please try again later.";
      if (e.response && e.response.status === 400) {
        errorMessage =
          "Found existing account for this information, please contact front desk for assistance.";
      } else {
        console.error(e);
      }
      this.errorMessage = errorMessage;
    } finally {
      this.isLoading = false;
    }
  }

  async createStudentAccount(personalInfo) {
    this.isLoading = true;
    this.personalInfo = personalInfo;
    try {
      await AuthApi.createStudentAccount(personalInfo);
      this.createStudentAccountSuccess = true;
    } catch (e) {
      console.error(e);
      this.createStudentAccountSuccess = false;
      this.errorMessage =
        "Error occurs while trying to register account, please try again.";
    } finally {
      this.isLoading = false;
    }
  }

  reset() {
    this.isLoading = false;
    this.personalInfo = { ...initialPersonalInfo };
    this.errorMessage = null;
    this.checkEmailAndNameSuccess = false;
    this.createStudentAccountSuccess = false;
  }

  set isLoading(value) {
    this._isLoading = value;
  }

  set errorMessage(value) {
    this._errorMessage = value;
  }

  set personalInfo(value) {
    this._personalInfo = value;
  }

  set checkEmailAndNameSuccess(value) {
    this._checkEmailAndNameSuccess = value;
  }

  set createStudentAccountSuccess(value) {
    this._createStudentAccountSuccess = value;
  }
}
